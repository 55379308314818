/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import QImg8Image from "src/assets/images/BirthdayPt2_Q_img8.png";
import QImg7Image from "src/assets/images/BirthdayPt2_Q_img7.png";
import QImg6Image from "src/assets/images/BirthdayPt2_Q_img6.png";
import QImg5Image from "src/assets/images/BirthdayPt2_Q_img5.png";
import QImg3Image from "src/assets/images/BirthdayPt2_Q_img3.png";
import QImg4Image from "src/assets/images/BirthdayPt2_Q_img4.png";
import QImg2Image from "src/assets/images/BirthdayPt2_Q_img2.png";
import QImg1Image from "src/assets/images/BirthdayPt2_Q_img1.png";
import { styled } from "@mui/material/styles";
import QuizButtonsM from "src/components/QuizButtonsM/QuizButtonsM";
import QuizButtonsD from "src/components/QuizButtonsD/QuizButtonsD";
import { animated, useSpring, easings } from "react-spring";
import { analytics } from "src/firebase.config";
import { EVENT_NAMES } from "src/constants/analytics.constants";
import { logEvent } from "firebase/analytics";

const Property1BrithdayPt2 = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  height: `770px`,
  width: "100%",
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Qs = styled("section", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  zIndex: props.isMobile ? "unset" : `1`,
  margin: "0px",
}));

const Q3Start = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q3Up4 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: props.isMobile ? `375px` : `1440px`,
  margin: `0px`,
}));

const Q3Up3 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const Q3Up2 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const Q3Up1 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const QuestionAndButtons34 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: props.isMobile ? `center` : `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    height: props.isMobile ? "unset" : `211px`,
    width: props.isMobile ? "unset" : `1440px`,
    margin: `0px`,
    alignSelf: props.isMobile ? `stretch` : "unset",
  }))
);

const QuestionAndButtons33 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: props.isMobile ? `center` : `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: props.isMobile ? "unset" : `stretch`,
    height: props.isMobile ? "unset" : `211px`,
    margin: `0px`,
    flexGrow: props.isMobile ? `1` : "unset",
  }))
);

const QuestionAndButtons32 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: props.isMobile ? `center` : `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: props.isMobile ? "unset" : `stretch`,
    height: props.isMobile ? "unset" : `211px`,
    margin: `0px`,
    flexGrow: props.isMobile ? `1` : "unset",
  }))
);

const QuestionAndButtons3 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: props.isMobile ? `40px 0px 0px 0px` : `49px 0px 0px 0px`,
    boxSizing: `border-box`,
    alignSelf: props.isMobile ? "unset" : `stretch`,
    height: props.isMobile ? "unset" : `211px`,
    margin: `0px`,
    flexGrow: props.isMobile ? `1` : "unset",
  }))
);

const Q3SlideOut4 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  }))
);

const Q3SlideOut3 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  }))
);

const Q3SlideOut2 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  }))
);

const Q3SlideOut = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  })
);

const WhatCognacStyleAreYo = styled("h1", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `24.513620376586914px` : `42px`,
  letterSpacing: props.isMobile ? `0.9805448150634766px` : `1.68px`,
  textDecoration: `none`,
  lineHeight: props.isMobile ? `30.350196838378906px` : `50px`,
  textTransform: `none`,
  width: props.isMobile ? "unset" : `1440px`,
  margin: `0px`,
}));

const Q3BtnsM = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `19px 0px 0px 0px` : `34px 0px 0px 0px`,
}));

const QuizButtonsM5 = styled(QuizButtonsM)({
  height: `47.9px`,
  margin: `0px`,
});

const QuizButtonsM6 = styled(QuizButtonsM)({
  height: `47.9px`,
  margin: `19px 0px 0px 0px`,
});

const Q3BtnsD = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `19px 0px 0px 0px` : `34px 0px 0px 0px`,
}));

const QuizButtonsD5 = styled(QuizButtonsD)({
  height: `78.01px`,
  margin: `0px`,
});

const QuizButtonsD6 = styled(QuizButtonsD)({
  height: `78.01px`,
  margin: `0px 0px 0px 50px`,
});

const Q2Start = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
}));

const Q2Up4 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: props.isMobile ? `375px` : `1440px`,
  margin: `0px`,
}));

const Q2Up3 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const Q2Up2 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const Q2Up1 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const QuestionAndButtons24 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: props.isMobile ? `center` : `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    height: props.isMobile ? "unset" : `211px`,
    width: props.isMobile ? `375px` : `1440px`,
    margin: `0px`,
  }))
);

const QuestionAndButtons23 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: props.isMobile ? `center` : `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: props.isMobile ? "unset" : `stretch`,
    height: props.isMobile ? "unset" : `211px`,
    margin: `0px`,
    flexGrow: props.isMobile ? `1` : "unset",
  }))
);

const QuestionAndButtons22 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: props.isMobile ? `center` : `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: props.isMobile ? "unset" : `stretch`,
    height: props.isMobile ? "unset" : `211px`,
    margin: `0px`,
    flexGrow: props.isMobile ? `1` : "unset",
  }))
);

const QuestionAndButtons2 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: props.isMobile ? `40px 0px 0px 0px` : `49px 0px 0px 0px`,
    boxSizing: `border-box`,
    alignSelf: props.isMobile ? "unset" : `stretch`,
    height: props.isMobile ? "unset" : `211px`,
    margin: `0px`,
    flexGrow: props.isMobile ? `1` : "unset",
  }))
);

const Q2SlideOut4 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    height: props.isMobile ? "unset" : `162.01px`,
    margin: `0px`,
  }))
);

const Q2SlideOut3 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    height: props.isMobile ? "unset" : `162.01px`,
    margin: `0px`,
  }))
);

const Q2SlideOut2 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    height: props.isMobile ? "unset" : `162.01px`,
    margin: `0px`,
  }))
);

const Q2SlideOut = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    height: props.isMobile ? "unset" : `162.01px`,
    margin: `0px`,
  }))
);

const WhatFlavorsDoYouPref = styled("h1", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `24.513620376586914px` : `42px`,
  letterSpacing: props.isMobile ? `0.9805448150634766px` : `1.68px`,
  textDecoration: `none`,
  lineHeight: props.isMobile ? `30.350196838378906px` : `50px`,
  textTransform: `none`,
  width: props.isMobile ? "unset" : `1440px`,
  margin: `0px`,
}));

const Q2BtnsM = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `19px 0px 0px 0px` : `34px 0px 0px 0px`,
}));

const QuizButtonsM3 = styled(QuizButtonsM)({
  height: `47.9px`,
  margin: `0px`,
});

const QuizButtonsM4 = styled(QuizButtonsM)({
  height: `47.9px`,
  margin: `19px 0px 0px 0px`,
});

const Q2BtnsD = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `19px 0px 0px 0px` : `34px 0px 0px 0px`,
}));

const QuizButtonsD3 = styled(QuizButtonsD)({
  height: `78.01px`,
  margin: `0px`,
});

const QuizButtonsD4 = styled(QuizButtonsD)({
  height: `78.01px`,
  margin: `0px 0px 0px 50px`,
});

const QuestionAndButtons14 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: props.isMobile ? `row` : `column`,
  justifyContent: props.isMobile ? `center` : `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: props.isMobile ? "unset" : `211px`,
  width: props.isMobile ? `375px` : `1440px`,
  margin: `0px`,
}));

const QuestionAndButtons13 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: props.isMobile ? `row` : `column`,
  justifyContent: props.isMobile ? `center` : `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: props.isMobile ? "unset" : `stretch`,
  height: props.isMobile ? "unset" : `211px`,
  margin: `0px`,
  flexGrow: props.isMobile ? `1` : "unset",
}));

const QuestionAndButtons12 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: props.isMobile ? `row` : `column`,
  justifyContent: props.isMobile ? `center` : `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: props.isMobile ? "unset" : `stretch`,
  height: props.isMobile ? "unset" : `211px`,
  margin: `0px`,
  flexGrow: props.isMobile ? `1` : "unset",
}));

const QuestionAndButtons1 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: props.isMobile ? `40px 0px 0px 0px` : `49px 0px 0px 0px`,
  boxSizing: `border-box`,
  alignSelf: props.isMobile ? "unset" : `stretch`,
  height: props.isMobile ? "unset" : `211px`,
  margin: `0px`,
  flexGrow: props.isMobile ? `1` : "unset",
}));

const Q1SlideOut4 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  }))
);

const Q1SlideOut3 = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: props.isMobile ? `center` : `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  }))
);

const Q1SlideOut2 = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  })
);

const Q1SlideOut = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  })
);

const WhichOfTheseBestDesc = styled("h1", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `24.513620376586914px` : `42px`,
  letterSpacing: props.isMobile ? `0.9805448150634766px` : `1.68px`,
  textDecoration: `none`,
  lineHeight: props.isMobile ? `30.350196838378906px` : `50px`,
  textTransform: `none`,
  width: props.isMobile ? `272px` : `1440px`,
  margin: `0px`,
}));

const Q1BtnsM = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `19px 0px 0px 0px` : `34px 0px 0px 0px`,
}));

const QuizButtonsM1 = styled(QuizButtonsM)({
  height: `47.9px`,
  margin: `0px`,
});

const QuizButtonsM2 = styled(QuizButtonsM)({
  height: `47.9px`,
  margin: `19px 0px 0px 0px`,
});

const Q1BtnsD = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `19px 0px 0px 0px` : `34px 0px 0px 0px`,
}));

const QuizButtonsD1 = styled(QuizButtonsD)({
  height: `78.01px`,
  margin: `0px`,
});

const QuizButtonsD2 = styled(QuizButtonsD)({
  height: `78.01px`,
  margin: `0px 0px 0px 50px`,
});

const QuestionShapes = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: props.isMobile ? `516.5px` : `650.5px`,
  width: props.isMobile ? `2061.63px` : `2596.5px`,
  zIndex: props.isMobile ? "unset" : `0`,
  left: props.isMobile ? `-19px` : `0px`,
  top: props.isMobile ? `256px` : `133px`,
}));

const SlideBottles6B = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles6A = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles5B = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles5A = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles4B = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles4A = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles3B = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles3A = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles2B = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles2A = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles1B = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const SlideBottles1A = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `2061.63px` : `2596.5px`,
    height: props.isMobile ? `516.5px` : `650.5px`,
    left: `0px`,
    top: `0px`,
  }))
);

const QImg8 = styled("img", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  height: props.isMobile ? `515.31px` : `649px`,
  width: props.isMobile ? `257.65px` : `324.5px`,
  objectFit: `cover`,
  position: `absolute`,
  left: props.isMobile ? `1804px` : `2272px`,
  top: `0px`,
}));

const QImg7 = styled("img", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  height: props.isMobile ? `257.65px` : `324.5px`,
  width: props.isMobile ? `257.65px` : `324.5px`,
  objectFit: `cover`,
  position: `absolute`,
  left: props.isMobile ? `1546px` : `1947px`,
  top: `1px`,
}));

const QImg6 = styled("img", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  height: props.isMobile ? `257.65px` : `324.5px`,
  width: props.isMobile ? `257.65px` : `324.5px`,
  objectFit: `cover`,
  position: `absolute`,
  left: props.isMobile ? `1546px` : `1947px`,
  top: props.isMobile ? `259px` : `326px`,
}));

const QImg5 = animated(
  styled("img", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    height: props.isMobile ? `515.31px` : `649px`,
    width: props.isMobile ? `515.31px` : `649px`,
    objectFit: `cover`,
    position: `absolute`,
    left: props.isMobile ? `1031px` : `1298px`,
    top: `1px`,
  }))
);

const QImg3 = animated(
  styled("img", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    height: props.isMobile ? `515.31px` : `649px`,
    width: props.isMobile ? `515.31px` : `649px`,
    objectFit: `cover`,
    position: `absolute`,
    left: props.isMobile ? `515px` : `649px`,
    top: `1px`,
  }))
);

const QImg4 = animated(
  styled("img", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    height: props.isMobile ? `257.65px` : `324.5px`,
    width: props.isMobile ? `257.65px` : `324.5px`,
    objectFit: `cover`,
    position: `absolute`,
    left: props.isMobile ? `773px` : `973px`,
    top: props.isMobile ? `258px` : `325px`,
  }))
);

const QImg2 = animated(
  styled("img", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    height: props.isMobile ? `515.31px` : `649px`,
    width: props.isMobile ? `515.31px` : `649px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  }))
);

const QImg1 = animated(
  styled("img", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    height: props.isMobile ? `257.65px` : `324.5px`,
    width: props.isMobile ? `257.65px` : `324.5px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: props.isMobile ? `258px` : `325px`,
  }))
);

function BirthdayPt2(props) {
  const [QuestionAndButtons34Spring, QuestionAndButtons34Api] = useSpring(
    () => ({
      config: {
        duration: 1100,
        easing: easings["easeOutExpo"],
      },
      delay: 0,
      from: { opacity: 1 },
    })
  );

  const [QuestionAndButtons33Spring, QuestionAndButtons33Api] = useSpring(
    () => ({
      config: {
        duration: 1100,
        easing: easings["easeOutExpo"],
      },
      delay: 0,
      from: { opacity: 1 },
    })
  );

  const [QuestionAndButtons32Spring, QuestionAndButtons32Api] = useSpring(
    () => ({
      config: {
        duration: 1100,
        easing: easings["easeOutExpo"],
      },
      delay: 0,
      from: { opacity: 1 },
    })
  );

  const [QuestionAndButtons3Spring, QuestionAndButtons3Api] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { opacity: 1 },
  }));

  const [Q3SlideOut4Spring, Q3SlideOut4Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q3SlideOut3Spring, Q3SlideOut3Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q3SlideOut2Spring, Q3SlideOut2Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q3SlideOutSpring, Q3SlideOutApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [QuestionAndButtons24Spring, QuestionAndButtons24Api] = useSpring(
    () => ({
      config: {
        duration: 1100,
        easing: easings["easeOutExpo"],
      },
      delay: 0,
      from: { opacity: 1 },
    })
  );

  const [QuestionAndButtons23Spring, QuestionAndButtons23Api] = useSpring(
    () => ({
      config: {
        duration: 1100,
        easing: easings["easeOutExpo"],
      },
      delay: 0,
      from: { opacity: 1 },
    })
  );

  const [QuestionAndButtons22Spring, QuestionAndButtons22Api] = useSpring(
    () => ({
      config: {
        duration: 1100,
        easing: easings["easeOutExpo"],
      },
      delay: 0,
      from: { opacity: 1 },
    })
  );

  const [QuestionAndButtons2Spring, QuestionAndButtons2Api] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeOutExpo"],
    },
    delay: 1000,
    from: { opacity: 1 },
  }));

  const [Q2SlideOut4Spring, Q2SlideOut4Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q2SlideOut3Spring, Q2SlideOut3Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q2SlideOut2Spring, Q2SlideOut2Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q2SlideOutSpring, Q2SlideOutApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 750,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q1SlideOut4Spring, Q1SlideOut4Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q1SlideOut3Spring, Q1SlideOut3Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q1SlideOut2Spring, Q1SlideOut2Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [Q1SlideOutSpring, Q1SlideOutApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)", opacity: 1 },
  }));

  const [SlideBottles6BSpring, SlideBottles6BApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [SlideBottles6ASpring, SlideBottles6AApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [SlideBottles5BSpring, SlideBottles5BApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutQuint"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [SlideBottles5ASpring, SlideBottles5AApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [SlideBottles4BSpring, SlideBottles4BApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutQuint"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [SlideBottles4ASpring, SlideBottles4AApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [SlideBottles3BSpring, SlideBottles3BApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [SlideBottles3ASpring, SlideBottles3AApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [SlideBottles2BSpring, SlideBottles2BApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [SlideBottles2ASpring, SlideBottles2AApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [SlideBottles1BSpring, SlideBottles1BApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [SlideBottles1ASpring, SlideBottles1AApi] = useSpring(() => ({
    config: {
      duration: 1100,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)" },
  }));

  const [QImg5Spring, QImg5Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [QImg3Spring, QImg3Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 500,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [QImg4Spring, QImg4Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 750,
    from: { transform: "translateX(100px)", opacity: 0 },
  }));

  const [QImg2Spring, QImg2Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(-100px)", opacity: 0 },
  }));

  const [QImg1Spring, QImg1Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 250,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  React.useEffect(() => {
    QImg1Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 250,
    });
    QImg2Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 0,
    });
    QImg3Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 500,
    });
    QImg4Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 750,
    });
    QImg5Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 0,
    });
  }, []);

  return (
    <Property1BrithdayPt2 className={props.className}>
      <Qs props={props} aria-label={"Questions Section"}>
        {props.step === 3 && (
          <Q3Start>
            <Q3Up4 props={props}>
              <Q3Up3>
                <Q3Up2>
                  <Q3Up1>
                    <QuestionAndButtons34
                      props={props}
                      style={{ ...QuestionAndButtons34Spring }}
                    >
                      <QuestionAndButtons33
                        props={props}
                        style={{ ...QuestionAndButtons33Spring }}
                      >
                        <QuestionAndButtons32
                          props={props}
                          style={{ ...QuestionAndButtons32Spring }}
                        >
                          <QuestionAndButtons3
                            props={props}
                            style={{ ...QuestionAndButtons3Spring }}
                          >
                            <Q3SlideOut4
                              props={props}
                              style={{ ...Q3SlideOut4Spring }}
                            >
                              <Q3SlideOut3
                                props={props}
                                style={{ ...Q3SlideOut3Spring }}
                              >
                                <Q3SlideOut2
                                  props={props}
                                  style={{ ...Q3SlideOut2Spring }}
                                >
                                  <Q3SlideOut style={{ ...Q3SlideOutSpring }}>
                                    <WhatCognacStyleAreYo props={props}>
                                      {props.isMobile
                                        ? `What cognac
style are you?`
                                        : `What cognac style are you?`}
                                    </WhatCognacStyleAreYo>
                                    {props.isMobile && (
                                      <Q3BtnsM props={props}>
                                        <QuizButtonsM5
                                          variant={"M5"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q3_ANSWER_1)
                                            Q3SlideOutApi.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles5AApi.start({
                                              ...{
                                                transform: "translateX(-600px)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vs");
                                              props.setStep(4);
                                            }, 1100);
                                          }}
                                          text={props.textVs}
                                        />
                                        <QuizButtonsM6
                                          variant={"M6"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q3_ANSWER_2)
                                            Q3SlideOut2Api.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles6BApi.start({
                                              ...{
                                                transform: "translateX(-600px)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vsop");
                                              props.setStep(4);
                                            }, 1100);
                                          }}
                                          text={props.textVsop}
                                        />
                                      </Q3BtnsM>
                                    )}
                                    {!props.isMobile && (
                                      <Q3BtnsD props={props}>
                                        <QuizButtonsD5
                                          variant={"D5"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q3_ANSWER_1)
                                            Q3SlideOut3Api.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles6AApi.start({
                                              ...{
                                                transform: "translateX(-500px)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vsop");
                                              props.setStep(4);
                                            }, 1100);
                                          }}
                                          text={props.textVsop}
                                        />
                                        <QuizButtonsD6
                                          variant={"D6"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q3_ANSWER_2)
                                            Q3SlideOut4Api.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles5BApi.start({
                                              ...{
                                                transform: "translateX(-500px)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vs");
                                              props.setStep(4);
                                            }, 1100);
                                          }}
                                          text={props.textVs}
                                        />
                                      </Q3BtnsD>
                                    )}
                                  </Q3SlideOut>
                                </Q3SlideOut2>
                              </Q3SlideOut3>
                            </Q3SlideOut4>
                          </QuestionAndButtons3>
                        </QuestionAndButtons32>
                      </QuestionAndButtons33>
                    </QuestionAndButtons34>
                  </Q3Up1>
                </Q3Up2>
              </Q3Up3>
            </Q3Up4>
          </Q3Start>
        )}
        {props.step === 2 && (
          <Q2Start props={props}>
            <Q2Up4 props={props}>
              <Q2Up3>
                <Q2Up2>
                  <Q2Up1>
                    <QuestionAndButtons24
                      props={props}
                      style={{ ...QuestionAndButtons24Spring }}
                    >
                      <QuestionAndButtons23
                        props={props}
                        style={{ ...QuestionAndButtons23Spring }}
                      >
                        <QuestionAndButtons22
                          props={props}
                          style={{ ...QuestionAndButtons22Spring }}
                        >
                          <QuestionAndButtons2
                            props={props}
                            style={{ ...QuestionAndButtons2Spring }}
                          >
                            <Q2SlideOut4
                              props={props}
                              style={{ ...Q2SlideOut4Spring }}
                            >
                              <Q2SlideOut3
                                props={props}
                                style={{ ...Q2SlideOut3Spring }}
                              >
                                <Q2SlideOut2
                                  props={props}
                                  style={{ ...Q2SlideOut2Spring }}
                                >
                                  <Q2SlideOut
                                    props={props}
                                    style={{ ...Q2SlideOutSpring }}
                                  >
                                    <WhatFlavorsDoYouPref props={props}>
                                      {props.isMobile
                                        ? `What flavors
do you prefer?`
                                        : `What flavors do you prefer?`}
                                    </WhatFlavorsDoYouPref>
                                    {props.isMobile && (
                                      <Q2BtnsM props={props}>
                                        <QuizButtonsM3
                                          variant={"M3"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q2_ANSWER_1)
                                            Q2SlideOut4Api.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles4BApi.start({
                                              ...{
                                                transform: "translateX(-600px)",
                                              },
                                              delay: 0,
                                            });
                                            QuestionAndButtons34Api.start({
                                              ...{ opacity: 1 },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vs");
                                              props.setStep(3);
                                            }, 1100);
                                          }}
                                          text={props.textVs}
                                        />
                                        <QuizButtonsM4
                                          variant={"M4"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q2_ANSWER_2)
                                            Q2SlideOut3Api.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles4AApi.start({
                                              ...{
                                                transform: "translateX(-600px)",
                                              },
                                              delay: 0,
                                            });
                                            QuestionAndButtons33Api.start({
                                              ...{ opacity: 1 },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vsop");
                                              props.setStep(3);
                                            }, 1100);
                                          }}
                                          text={props.textVsop}
                                        />
                                      </Q2BtnsM>
                                    )}
                                    {!props.isMobile && (
                                      <Q2BtnsD props={props}>
                                        <QuizButtonsD3
                                          variant={"D3"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q2_ANSWER_1)
                                            Q2SlideOut2Api.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 0,
                                            });
                                            SlideBottles3BApi.start({
                                              ...{
                                                transform: "translateX(-500px)",
                                              },
                                              delay: 0,
                                            });
                                            QuestionAndButtons32Api.start({
                                              ...{ opacity: 1 },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vsop");
                                              props.setStep(3);
                                            }, 1100);
                                          }}
                                          text={props.textVsop}
                                        />
                                        <QuizButtonsD4
                                          variant={"D4"}
                                          onClick={() => {
                                            logEvent(analytics, EVENT_NAMES.Q2_ANSWER_2)
                                            Q2SlideOutApi.start({
                                              ...{
                                                transform: "scale(0.9)",
                                                opacity: 0,
                                              },
                                              delay: 750,
                                            });
                                            SlideBottles3AApi.start({
                                              ...{
                                                transform: "translateX(-500px)",
                                              },
                                              delay: 0,
                                            });
                                            QuestionAndButtons3Api.start({
                                              ...{ opacity: 1 },
                                              delay: 0,
                                            });
                                            setTimeout(() => {
                                              props.setResponse("Vs");
                                              props.setStep(3);
                                            }, 1100);
                                          }}
                                          text={props.textVs}
                                        />
                                      </Q2BtnsD>
                                    )}
                                  </Q2SlideOut>
                                </Q2SlideOut2>
                              </Q2SlideOut3>
                            </Q2SlideOut4>
                          </QuestionAndButtons2>
                        </QuestionAndButtons22>
                      </QuestionAndButtons23>
                    </QuestionAndButtons24>
                  </Q2Up1>
                </Q2Up2>
              </Q2Up3>
            </Q2Up4>
          </Q2Start>
        )}
        {props.step === 1 && (
          <QuestionAndButtons14 props={props}>
            <QuestionAndButtons13 props={props}>
              <QuestionAndButtons12 props={props}>
                <QuestionAndButtons1 props={props}>
                  <Q1SlideOut4 props={props} style={{ ...Q1SlideOut4Spring }}>
                    <Q1SlideOut3 props={props} style={{ ...Q1SlideOut3Spring }}>
                      <Q1SlideOut2 style={{ ...Q1SlideOut2Spring }}>
                        <Q1SlideOut style={{ ...Q1SlideOutSpring }}>
                          <WhichOfTheseBestDesc props={props}>
                            {props.isMobile
                              ? `Which of these
best describe you?`
                              : `Which of these best describe you?`}
                          </WhichOfTheseBestDesc>
                          {props.isMobile && (
                            <Q1BtnsM props={props}>
                              <QuizButtonsM1
                                variant={"M1"}
                                onClick={() => {
                                  logEvent(analytics, EVENT_NAMES.Q1_ANSWER_1)
                                  SlideBottles2BApi.start({
                                    ...{
                                      transform: "translateX(-600px)",
                                    },
                                    delay: 0,
                                  });
                                  Q1SlideOut4Api.start({
                                    ...{ transform: "scale(0.9)", opacity: 0 },
                                    delay: 0,
                                  });
                                  QuestionAndButtons2Api.start({
                                    ...{ opacity: 1 },
                                    delay: 1000,
                                  });
                                  setTimeout(() => {
                                    props.setResponse("Vs");
                                    props.setStep(2);
                                  }, 1100);
                                }}
                                text={props.textVs}
                              />
                              <QuizButtonsM2
                                variant={"M2"}
                                onClick={() => {
                                  logEvent(analytics, EVENT_NAMES.Q1_ANSWER_2)
                                  SlideBottles2AApi.start({
                                    ...{ transform: "translateX(-600px)" },
                                    delay: 0,
                                  });
                                  Q1SlideOut3Api.start({
                                    ...{ transform: "scale(0.9)", opacity: 0 },
                                    delay: 0,
                                  });
                                  QuestionAndButtons22Api.start({
                                    ...{ opacity: 1 },
                                    delay: 0,
                                  });
                                  setTimeout(() => {
                                    props.setResponse("Vs");
                                    props.setStep(2);
                                  }, 1100);
                                }}
                                text={props.textVsop}
                              />
                            </Q1BtnsM>
                          )}
                          {!props.isMobile && (
                            <Q1BtnsD props={props}>
                              <QuizButtonsD1
                                variant={"D1"}
                                onClick={() => {
                                  logEvent(analytics, EVENT_NAMES.Q1_ANSWER_1)
                                  SlideBottles1BApi.start({
                                    ...{ transform: "translateX(-500px)" },
                                    delay: 0,
                                  });
                                  Q1SlideOut2Api.start({
                                    ...{ transform: "scale(0.9)", opacity: 0 },
                                    delay: 0,
                                  });
                                  QuestionAndButtons23Api.start({
                                    ...{ opacity: 1 },
                                    delay: 0,
                                  });
                                  setTimeout(() => {
                                    props.setResponse("Vsop");
                                    props.setStep(2);
                                  }, 1100);
                                }}
                                text={props.textVsop}
                              />
                              <QuizButtonsD2
                                variant={"D2"}
                                onClick={() => {
                                  logEvent(analytics, EVENT_NAMES.Q1_ANSWER_2)
                                  SlideBottles1AApi.start({
                                    ...{ transform: "translateX(-500px)" },
                                    delay: 0,
                                  });
                                  Q1SlideOutApi.start({
                                    ...{ transform: "scale(0.9)", opacity: 0 },
                                    delay: 0,
                                  });
                                  QuestionAndButtons24Api.start({
                                    ...{ opacity: 1 },
                                    delay: 0,
                                  });
                                  setTimeout(() => {
                                    props.setResponse("Vs");
                                    props.setStep(2);
                                  }, 1100);
                                }}
                                text={props.textVs}
                              />
                            </Q1BtnsD>
                          )}
                        </Q1SlideOut>
                      </Q1SlideOut2>
                    </Q1SlideOut3>
                  </Q1SlideOut4>
                </QuestionAndButtons1>
              </QuestionAndButtons12>
            </QuestionAndButtons13>
          </QuestionAndButtons14>
        )}
      </Qs>
      <QuestionShapes props={props}>
        <SlideBottles6B props={props} style={{ ...SlideBottles6BSpring }}>
          <SlideBottles6A props={props} style={{ ...SlideBottles6ASpring }}>
            <SlideBottles5B props={props} style={{ ...SlideBottles5BSpring }}>
              <SlideBottles5A props={props} style={{ ...SlideBottles5ASpring }}>
                <SlideBottles4B
                  props={props}
                  style={{ ...SlideBottles4BSpring }}
                >
                  <SlideBottles4A
                    props={props}
                    style={{ ...SlideBottles4ASpring }}
                  >
                    <SlideBottles3B
                      props={props}
                      style={{ ...SlideBottles3BSpring }}
                    >
                      <SlideBottles3A
                        props={props}
                        style={{ ...SlideBottles3ASpring }}
                      >
                        <SlideBottles2B
                          props={props}
                          style={{ ...SlideBottles2BSpring }}
                        >
                          <SlideBottles2A
                            props={props}
                            style={{ ...SlideBottles2ASpring }}
                          >
                            <SlideBottles1B
                              props={props}
                              style={{ ...SlideBottles1BSpring }}
                            >
                              <SlideBottles1A
                                props={props}
                                style={{ ...SlideBottles1ASpring }}
                              >
                                <QImg8
                                  props={props}
                                  src={QImg8Image}
                                  alt={"Hennessy Bottle and Cocktail"}
                                />
                                <QImg7
                                  props={props}
                                  src={QImg7Image}
                                  alt={
                                    "Hennessy Hennessy Bottle and CocktailCocktail"
                                  }
                                />
                                <QImg6
                                  props={props}
                                  src={QImg6Image}
                                  alt={"Hennessy Bottle and Cocktail"}
                                />
                                <QImg5
                                  props={props}
                                  style={{ ...QImg5Spring }}
                                  src={QImg5Image}
                                  alt={"Hennessy Bottle and Cocktail"}
                                />
                                <QImg3
                                  props={props}
                                  style={{ ...QImg3Spring }}
                                  src={QImg3Image}
                                  alt={"Hennessy Cocktail"}
                                />
                                <QImg4
                                  props={props}
                                  style={{ ...QImg4Spring }}
                                  src={QImg4Image}
                                  alt={"Hennessy Bottle and Cocktail"}
                                />
                                <QImg2
                                  props={props}
                                  style={{ ...QImg2Spring }}
                                  src={QImg2Image}
                                  alt={"Hennessy Bottle and Cocktail"}
                                />
                                <QImg1
                                  props={props}
                                  style={{ ...QImg1Spring }}
                                  src={QImg1Image}
                                  alt={"Hennessy Bottle and Cocktail"}
                                />
                              </SlideBottles1A>
                            </SlideBottles1B>
                          </SlideBottles2A>
                        </SlideBottles2B>
                      </SlideBottles3A>
                    </SlideBottles3B>
                  </SlideBottles4A>
                </SlideBottles4B>
              </SlideBottles5A>
            </SlideBottles5B>
          </SlideBottles6A>
        </SlideBottles6B>
      </QuestionShapes>
    </Property1BrithdayPt2>
  );
}

export default BirthdayPt2;
