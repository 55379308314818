/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import MonthShapeImage from "src/assets/images/BirthdayPt1_month_shape.png";
import SImg8Image from "src/assets/images/BirthdayPt1_S_img8_1.png";
import SImg7Image from "src/assets/images/BirthdayPt1_S_img7_1.png";
import SImg6Image from "src/assets/images/BirthdayPt1_S_img6_1.png";
import SImg5Image from "src/assets/images/BirthdayPt1_S_img5_1.png";
import SImg4Image from "src/assets/images/BirthdayPt1_S_img4_1.png";
import SImg3Image from "src/assets/images/BirthdayPt1_S_img3_1.png";
import SImg2Image from "src/assets/images/BirthdayPt1_S_img2_1.png";
import SImg1Image from "src/assets/images/BirthdayPt1_S_img1_1.png";
import SmImg8Image from "src/assets/images/BirthdayPt1_Sm_img8.png";
import SmImg7Image from "src/assets/images/BirthdayPt1_Sm_img7.png";
import SmImg6Image from "src/assets/images/BirthdayPt1_Sm_img6.png";
import SmImg5Image from "src/assets/images/BirthdayPt1_Sm_img5.png";
import SmImg4Image from "src/assets/images/BirthdayPt1_Sm_img4.png";
import SmImg3Image from "src/assets/images/BirthdayPt1_Sm_img3.png";
import SmImg2Image from "src/assets/images/BirthdayPt1_Sm_img2.png";
import SmImg1Image from "src/assets/images/BirthdayPt1_Sm_img1.png";
import SImg81Image from "src/assets/images/BirthdayPt1_S_img8.png";
import SImg71Image from "src/assets/images/BirthdayPt1_S_img7.png";
import SImg61Image from "src/assets/images/BirthdayPt1_S_img6.png";
import SImg51Image from "src/assets/images/BirthdayPt1_S_img5.png";
import SImg41Image from "src/assets/images/BirthdayPt1_S_img4.png";
import SImg31Image from "src/assets/images/BirthdayPt1_S_img3.png";
import SImg21Image from "src/assets/images/BirthdayPt1_S_img2.png";
import SImg11Image from "src/assets/images/BirthdayPt1_S_img1.png";
import { styled } from "@mui/material/styles";
import { animated, useSpring, easings } from "react-spring";
import { analytics } from "src/firebase.config";
import { EVENT_NAMES } from "src/constants/analytics.constants";
import { logEvent } from "firebase/analytics";

const Property1Desktop = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  height: `770px`,
  width: "100%",
  justifyContent: props.isMobile ? `center` : `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
}));

const MonthShape = styled("img", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  height: `805px`,
  width: `805px`,
  position: `absolute`,
  left: props.isMobile ? `-194px` : `0px`,
  top: props.isMobile ? `-19px` : `0px`,
}));

const BottlesMHolder = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: `41px 0px 0px 0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    flexGrow: `1`,
    margin: props.isMobile ? `0px` : `-618px 0px 0px 0px`,
  }))
);

const BottlesM = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `693.91px`,
  width: `1407px`,
  margin: `0px`,
});

const StartBottles = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1047px`,
  height: `348.91px`,
  left: `0px`,
  top: `345px`,
});

const RightSide = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `523.37px`,
  height: `348.91px`,
  left: `524px`,
  top: `0px`,
});

const SImg8 = animated(
  styled("img")({
    height: `348.91px`,
    width: `349.18px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `174px`,
    top: `0px`,
  })
);

const SImg7 = animated(
  styled("img")({
    height: `174.46px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `174px`,
    top: `0px`,
  })
);

const SImg6 = animated(
  styled("img")({
    height: `174.46px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SImg5 = animated(
  styled("img")({
    height: `174.72px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `174px`,
  })
);

const LeftSide = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `523.63px`,
  height: `348.91px`,
  left: `0px`,
  top: `0px`,
});

const SImg4 = animated(
  styled("img")({
    height: `174.46px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `349px`,
    top: `0px`,
  })
);

const SImg3 = animated(
  styled("img")({
    height: `174.72px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `349px`,
    top: `174px`,
  })
);

const SImg2 = styled("img")({
  height: `348.91px`,
  width: `348.91px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SImg1 = styled("img")({
  height: `174.72px`,
  width: `174.46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `174px`,
});

const StartBottles1 = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1047px`,
  height: `348.91px`,
  left: `360px`,
  top: `0px`,
});

const RightSide1 = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `523.37px`,
  height: `348.91px`,
  left: `524px`,
  top: `0px`,
});

const SmImg8H = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `349.18px`,
  height: `348.91px`,
  left: `174px`,
  top: `0px`,
});

const SmImg8 = styled("img")({
  height: `348.91px`,
  width: `349.18px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SmImg7H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `174.72px`,
    height: `174.46px`,
    left: `174px`,
    top: `0px`,
  })
);

const SmImg7 = styled("img")({
  height: `174.46px`,
  width: `174.72px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SmImg6H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `174.72px`,
    height: `174.46px`,
    left: `0px`,
    top: `0px`,
  })
);

const SmImg6 = animated(
  styled("img")({
    height: `174.46px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SmImg5H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `174.72px`,
    height: `174.72px`,
    left: `0px`,
    top: `174px`,
  })
);

const SmImg5 = animated(
  styled("img")({
    height: `174.72px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const LeftSide1 = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `523.63px`,
  height: `348.91px`,
  left: `0px`,
  top: `0px`,
});

const SmImg4H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `174.72px`,
    height: `174.46px`,
    left: `349px`,
    top: `0px`,
  })
);

const SmImg4 = animated(
  styled("img")({
    height: `174.46px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SmImg3H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `174.72px`,
    height: `174.72px`,
    left: `349px`,
    top: `174px`,
  })
);

const SmImg3 = animated(
  styled("img")({
    height: `174.72px`,
    width: `174.72px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SmImg2H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `348.91px`,
    height: `348.91px`,
    left: `0px`,
    top: `0px`,
  })
);

const SmImg2 = animated(
  styled("img")({
    height: `348.91px`,
    width: `348.91px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SmImg1H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `174.46px`,
    height: `174.72px`,
    left: `0px`,
    top: `174px`,
  })
);

const SmImg1 = animated(
  styled("img")({
    height: `174.72px`,
    width: `174.46px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const BottlesDHolder = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    padding: `51px 0px 0px 0px`,
    boxSizing: `border-box`,
    margin: props.isMobile ? `-747px 0px 0px 0px` : `0px`,
  }))
);

const BottlesD = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `657px`,
  width: `1972px`,
  margin: `0px`,
});

const RightSide2 = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `985.5px`,
  height: `657px`,
  left: `986px`,
  top: `0px`,
});

const SImg8H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `657.5px`,
    height: `657px`,
    left: `328px`,
    top: `0px`,
  })
);

const SImg81 = styled("img")({
  height: `657px`,
  width: `657.5px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SImg7H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `329px`,
    height: `328.5px`,
    left: `328px`,
    top: `0px`,
  })
);

const SImg71 = styled("img")({
  height: `328.5px`,
  width: `329px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SImg6H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `329px`,
    height: `328.5px`,
    left: `0px`,
    top: `0px`,
  })
);

const SImg61 = styled("img")({
  height: `328.5px`,
  width: `329px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SImg5H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `329px`,
    height: `329px`,
    left: `0px`,
    top: `328px`,
  })
);

const SImg51 = styled("img")({
  height: `329px`,
  width: `329px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const LeftSide2 = styled("div")({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `986px`,
  height: `657px`,
  left: `0px`,
  top: `0px`,
});

const SImg4H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `329px`,
    height: `328.5px`,
    left: `657px`,
    top: `0px`,
  })
);

const SImg41 = animated(
  styled("img")({
    height: `328.5px`,
    width: `329px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SImg3H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `329px`,
    height: `329px`,
    left: `657px`,
    top: `328px`,
  })
);

const SImg31 = animated(
  styled("img")({
    height: `329px`,
    width: `329px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SImg2H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `657px`,
    height: `657px`,
    left: `0px`,
    top: `0px`,
  })
);

const SImg21 = animated(
  styled("img")({
    height: `657px`,
    width: `657px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const SImg1H = animated(
  styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `328.5px`,
    height: `329px`,
    left: `0px`,
    top: `328px`,
  })
);

const SImg11 = animated(
  styled("img")({
    height: `329px`,
    width: `328.5px`,
    objectFit: `cover`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  })
);

const HideBottles = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `959px`,
  width: props.isMobile ? `963px` : `1944px`,
  left: props.isMobile ? `-281px` : `-252px`,
  top: `-89px`,
  overflow: `hidden`,
}));

const HideJan = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideFeb = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideMar = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideApr = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideMay = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideJun = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideJul = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideAug = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideSep = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideOct = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideNov = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const HideDec = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    backgroundColor: `rgba(255, 255, 255, 1)`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    width: `1690px`,
    height: `861px`,
    left: props.isMobile ? `-363px` : `124px`,
    top: `18px`,
  }))
);

const StartHolderParent = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: props.isMobile ? `row` : `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile ? `-747px 0px 0px 0px` : `-618px 0px 0px 0px`,
  }))
);

const StartHolder = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    height: props.isMobile ? `758.5px` : `680px`,
    width: props.isMobile ? `375px` : `1440px`,
    margin: `0px`,
  }))
);

const StartPopOver = animated(
  styled("section", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    height: props.isMobile ? `143px` : `327px`,
    width: props.isMobile ? `373.97px` : `855.16px`,
    margin: `0px`,
  }))
);

const Blur = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  background: props.isMobile
    ? `linear-gradient(180deg, rgba(255, 255, 255, 1) -4.780546188198113%, rgba(255, 255, 255, 0.35) 115.10450227290627%)`
    : `linear-gradient(180deg, rgba(255, 255, 255, 1) -4.7805467391481145%, rgba(255, 255, 255, 0.35) 115.10450165609849%)`,
  backdropFilter: props.isMobile
    ? `blur(28.99613380432129px)`
    : `blur(66.30585479736328px)`,
  WebkitBackdropFilter: props.isMobile
    ? `blur(28.99613380432129px)`
    : `blur(66.30585479736328px)`,
  boxSizing: `border-box`,
  borderRadius: props.isMobile
    ? `11.755188941955566px`
    : `26.880752563476562px`,
  width: props.isMobile ? `350px` : `800.35px`,
  height: props.isMobile ? `130px` : `297.27px`,
  position: `absolute`,
  left: props.isMobile ? `12px` : `27px`,
  top: `0px`,
}));

const DropShadow = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  background: props.isMobile
    ? `linear-gradient(180deg, rgba(255, 255, 255, 0) -4.780546188198113%, rgba(255, 255, 255, 0) 115.10450227290627%)`
    : `linear-gradient(180deg, rgba(255, 255, 255, 0) -4.7805467391481145%, rgba(255, 255, 255, 0) 115.10450165609849%)`,
  boxShadow: props.isMobile
    ? `0px 1.5673586130142212px 11.755188941955566px rgba(0, 0, 0, 0.25)`
    : `0px 3.5841004848480225px 26.880752563476562px rgba(0, 0, 0, 0.25)`,
  borderRadius: props.isMobile
    ? `11.755188941955566px`
    : `26.880752563476562px`,
  width: props.isMobile ? `350px` : `800.35px`,
  height: props.isMobile ? `130px` : `297.27px`,
  position: `absolute`,
  left: props.isMobile ? `12px` : `27px`,
  top: `0px`,
}));

const Txt = styled("hgroup", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: props.isMobile ? `263px` : `602px`,
  height: props.isMobile ? `54.8px` : `114.15px`,
  left: props.isMobile ? `56px` : `127px`,
  top: props.isMobile ? `20px` : `51px`,
}));

const FindYour = animated(
  styled("h3", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `center`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `16px` : `26px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    position: `absolute`,
    left: props.isMobile ? `89px` : `234px`,
    top: `0px`,
    margin: `0px`,
  }))
);

const BirthdayCocktail = animated(
  styled("h1", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `center`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `27.127361297607422px` : `62.03251266479492px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    position: `absolute`,
    left: `0px`,
    top: props.isMobile ? `23px` : `41px`,
    margin: `0px`,
  }))
);

const Btn = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    // backgroundColor: `rgba(255, 255, 255, 1)`,
    border: `2px solid rgb(77 77 77)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `absolute`,
    cursor: `pointer`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `2.742877721786499px 51.72283172607422px`
      : `6.272176742553711px 118.27531433105469px`,
    width: props.isMobile ? `107.76px` : `246.41px`,
    height: props.isMobile ? `25.08px` : `57.35px`,
    left: props.isMobile ? `133px` : `305px`,
    top: props.isMobile ? `84px` : `186px`,
    textAlign: `center`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `11.755188941955566px` : `26.880752563476562px`,
    letterSpacing: props.isMobile
      ? `1.8808302307128906px`
      : `4.30092041015625px`,
    textDecoration: `none`,
    textTransform: `none`,
  }))
);

const Begin = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `center`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `11.755188941955566px` : `26.880752563476562px`,
    letterSpacing: props.isMobile
      ? `1.8808302307128906px`
      : `4.30092041015625px`,
    textDecoration: `none`,
    textTransform: `none`,
    margin: `0px`,
  }))
);

const Months = styled("section", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `10px`,
  boxSizing: `border-box`,
  left: props.isMobile ? `-130px` : `-90px`,
  top: props.isMobile ? `-89px` : `24px`,
  height: props.isMobile ? `479px` : "unset",
}));

const EndDec = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `469px` : "unset",
  }))
);

const EndNov = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `459px` : "unset",
  }))
);

const EndOct = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `449px` : "unset",
  }))
);

const EndSep = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `439px` : "unset",
  }))
);

const EndAug = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `429px` : "unset",
  }))
);

const EndJul = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `419px` : "unset",
  }))
);

const EndJun = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `409px` : "unset",
  }))
);

const EndMay = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `399px` : "unset",
  }))
);

const EndApr = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `389px` : "unset",
  }))
);

const EndMar = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `379px` : "unset",
  }))
);

const EndFeb = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
    height: props.isMobile ? `369px` : "unset",
  }))
);

const EndJan = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    margin: `0px`,
  })
);

const PickMonth = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile
    ? `0px 0px 0px 33.772178649902344px`
    : `0px 0px 0px 100px`,
  boxSizing: `border-box`,
  width: props.isMobile ? `374px` : `564px`,
  margin: `0px`,
  height: props.isMobile ? `339px` : "unset",
}));

const SelectTxt = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    height: props.isMobile ? `79px` : `104px`,
    width: props.isMobile ? `289.52px` : `383px`,
    margin: `0px`,
  }))
);

const SelectYourBirthMonth = animated(
  styled("h1", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `30px` : `42px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: props.isMobile ? `34px` : `52px`,
    textTransform: `none`,
    opacity: `0`,
    width: props.isMobile ? `237px` : `383px`,
    position: `absolute`,
    left: `100px`,
    top: `0px`,
    margin: `0px`,
  }))
);

const Months1 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: props.isMobile ? `14px 0px 0px 0px` : `30px 0px 0px 0px`,
}));

const Row1 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `7.559312343597412px` : `10px`,
  boxSizing: `border-box`,
  margin: `0px`,
}));

const BtnHoverJan = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: `0px`,
  })
);

const BtnJan = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Jan = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `center`,
    whiteSpace: `pre-wrap`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
    letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
    textDecoration: `none`,
    textTransform: `none`,
    margin: `0px`,
  }))
);

const BtnHoverFeb = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnFeb = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Feb = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverMar = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnMar = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Mar = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const Row2 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `7.559312343597412px` : `10px`,
  boxSizing: `border-box`,
  margin: props.isMobile
    ? `10.583038330078125px 0px 0px 0px`
    : `14px 0px 0px 0px`,
}));

const BtnHoverApr = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: `0px`,
  })
);

const BtnApr = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Apr = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverMay = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnMay = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const May = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverJun = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnJun = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Jun = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const Row3 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `7.559312343597412px` : `10px`,
  boxSizing: `border-box`,
  margin: props.isMobile
    ? `10.583038330078125px 0px 0px 0px`
    : `14px 0px 0px 0px`,
}));

const BtnHoverJul = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: `0px`,
  })
);

const BtnJul = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Jul = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverAug = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnAug = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Aug = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverSep = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnSep = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Sep = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const Row4 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `7.559312343597412px` : `10px`,
  boxSizing: `border-box`,
  margin: props.isMobile
    ? `10.583038330078125px 0px 0px 0px`
    : `14px 0px 0px 0px`,
}));

const BtnHoverOct = animated(
  styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: `0px`,
  })
);

const BtnOct = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Oct = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverNov = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnNov = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Nov = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const BtnHoverDec = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: props.isMobile
      ? `0px 0px 0px 30.23724937438965px`
      : `0px 0px 0px 40px`,
  }))
);

const BtnDec = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    opacity: `0`,
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `4.795876979827881px 18.142349243164062px`
      : `6.344329833984375px 24px`,
    height: props.isMobile ? `43.85px` : `58.01px`,
    width: props.isMobile ? `75.59px` : `100px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const Dec = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `18.142349243164062px` : `24px`,
  letterSpacing: props.isMobile ? `2.90277587890625px` : `3.84px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

function BirthdayPt1(props) {
  const [isMonth, setIsMonth] = React.useState(false);

  const [BottlesMHolderSpring, BottlesMHolderApi] = useSpring(() => ({
    config: {
      duration: 10000,
      easing: easings["linear"],
    },
    delay: 0,
    from: { transform: "scale(1.15)" },
  }));

  const [SImg8Spring, SImg8Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3600,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [SImg7Spring, SImg7Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3400,
    from: { transform: "translateX(75px)", opacity: 0 },
  }));

  const [SImg6Spring, SImg6Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3000,
    from: { transform: "translateX(-75px)", opacity: 0 },
  }));

  const [SImg5Spring, SImg5Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3200,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [SImg4Spring, SImg4Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3600,
    from: { transform: "translateX(-100px)", opacity: 0 },
  }));

  const [SImg3Spring, SImg3Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3800,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [SmImg7HSpring, SmImg7HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 1500,
    from: { opacity: 1 },
  }));

  const [SmImg6HSpring, SmImg6HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 1250,
    from: { opacity: 1 },
  }));

  const [SmImg6Spring, SmImg6Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3000,
    from: { transform: "translateY(-50px)", opacity: 0 },
  }));

  const [SmImg5HSpring, SmImg5HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 1000,
    from: { opacity: 1 },
  }));

  const [SmImg5Spring, SmImg5Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2800,
    from: { transform: "translateX(100px)", opacity: 0 },
  }));

  const [SmImg4HSpring, SmImg4HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 750,
    from: { opacity: 1 },
  }));

  const [SmImg4Spring, SmImg4Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2600,
    from: { transform: "translateX(-75px)", opacity: 0 },
  }));

  const [SmImg3HSpring, SmImg3HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 1 },
  }));

  const [SmImg3Spring, SmImg3Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2400,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [SmImg2HSpring, SmImg2HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 250,
    from: { opacity: 1 },
  }));

  const [SmImg2Spring, SmImg2Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2200,
    from: { transform: "translateY(-50px)", opacity: 0 },
  }));

  const [SmImg1HSpring, SmImg1HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { opacity: 1 },
  }));

  const [SmImg1Spring, SmImg1Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2000,
    from: { transform: "translateX(-100px)", opacity: 0 },
  }));

  const [BottlesDHolderSpring, BottlesDHolderApi] = useSpring(() => ({
    config: {
      duration: 15000,
      easing: easings["linear"],
    },
    delay: 0,
    from: { transform: "scale(1.2)" },
  }));

  const [SImg8HSpring, SImg8HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3400,
    from: { transform: "translateX(100px)", opacity: 0 },
  }));

  const [SImg7HSpring, SImg7HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3000,
    from: { transform: "translateY(-50px)", opacity: 0 },
  }));

  const [SImg6HSpring, SImg6HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 3200,
    from: { transform: "translateY(-50px)", opacity: 0 },
  }));

  const [SImg5HSpring, SImg5HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2800,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [SImg4HSpring, SImg4HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2400,
    from: { transform: "translateX(-100px)", opacity: 0 },
  }));

  const [SImg41Spring, SImg41Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 750,
    from: { opacity: 1 },
  }));

  const [SImg3HSpring, SImg3HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2600,
    from: { transform: "translateY(50px)", opacity: 0 },
  }));

  const [SImg31Spring, SImg31Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 1 },
  }));

  const [SImg2HSpring, SImg2HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2200,
    from: { transform: "translateY(-50px)", opacity: 0 },
  }));

  const [SImg21Spring, SImg21Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 250,
    from: { opacity: 1 },
  }));

  const [SImg1HSpring, SImg1HApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2000,
    from: { transform: "translateX(-100px)", opacity: 0 },
  }));

  const [SImg11Spring, SImg11Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { opacity: 1 },
  }));

  const [HideJanSpring, HideJanApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideFebSpring, HideFebApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideMarSpring, HideMarApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideAprSpring, HideAprApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideMaySpring, HideMayApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideJunSpring, HideJunApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideJulSpring, HideJulApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideAugSpring, HideAugApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideSepSpring, HideSepApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideOctSpring, HideOctApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideNovSpring, HideNovApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [HideDecSpring, HideDecApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 500,
    from: { opacity: 0 },
  }));

  const [StartHolderParentSpring, StartHolderParentApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 1250,
    from: { transform: "translateY(500px)", opacity: 0 },
  }));

  const [StartHolderSpring, StartHolderApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeInOutCubic"],
    },
    delay: 0,
    from: { transform: "translateY(0px)", opacity: 1 },
  }));

  const [StartPopOverSpring, StartPopOverApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 2000,
    from: { transform: "translateY(200px)", opacity: 0 },
  }));

  const [FindYourSpring, FindYourApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 2250,
    from: { opacity: 0 },
  }));

  const [BirthdayCocktailSpring, BirthdayCocktailApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 2500,
    from: { opacity: 0 },
  }));

  const [BtnSpring, BtnApi] = useSpring(() => ({
    config: {
      duration: 150,
      easing: easings["easeOutBounce"],
    },
    delay: 0,
    from: { backgroundColor: "rgb(255,255,255)", opacity: 1 },
  }));

  const [BeginSpring, BeginApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgb(77,77,77)" },
  }));

  const [EndDecSpring, EndDecApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndNovSpring, EndNovApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndOctSpring, EndOctApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndSepSpring, EndSepApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndAugSpring, EndAugApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndJulSpring, EndJulApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndJunSpring, EndJunApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndMaySpring, EndMayApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndAprSpring, EndAprApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndMarSpring, EndMarApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndFebSpring, EndFebApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [EndJanSpring, EndJanApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateX(0px)", opacity: 1 },
  }));

  const [SelectTxtSpring, SelectTxtApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 1000,
    from: { transform: "translateX(0px)" },
  }));

  const [SelectYourBirthMonthSpring, SelectYourBirthMonthApi] = useSpring(
    () => ({
      config: {
        duration: 1250,
        easing: easings["easeOutCubic"],
      },
      delay: 1000,
      from: { opacity: 0 },
    })
  );

  const [BtnJanSpring, BtnJanApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1100,
    from: { backgroundColor: "rgba(255,255,255,1)", opacity: 0 },
  }));

  const [JanSpring, JanApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [FebSpring, FebApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [MarSpring, MarApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [AprSpring, AprApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [MaySpring, MayApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [JunSpring, JunApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [JulSpring, JulApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [AugSpring, AugApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [SepSpring, SepApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [OctSpring, OctApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [NovSpring, NovApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [DecSpring, DecApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [BtnHoverFebSpring, BtnHoverFebApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnFebSpring, BtnFebApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1200,
    from: { opacity: 0 },
  }));

  const [BtnHoverMarSpring, BtnHoverMarApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnMarSpring, BtnMarApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1300,
    from: { opacity: 0 },
  }));

  const [BtnHoverAprSpring, BtnHoverAprApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnAprSpring, BtnAprApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1400,
    from: { opacity: 0 },
  }));

  const [BtnHoverMaySpring, BtnHoverMayApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnMaySpring, BtnMayApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1500,
    from: { opacity: 0 },
  }));

  const [BtnHoverJunSpring, BtnHoverJunApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnJunSpring, BtnJunApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1600,
    from: { opacity: 0 },
  }));

  const [BtnHoverJulSpring, BtnHoverJulApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnJulSpring, BtnJulApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1700,
    from: { opacity: 0 },
  }));

  const [BtnHoverAugSpring, BtnHoverAugApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnAugSpring, BtnAugApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1800,
    from: { opacity: 0 },
  }));

  const [BtnHoverSepSpring, BtnHoverSepApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnSepSpring, BtnSepApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 1900,
    from: { opacity: 0 },
  }));

  const [BtnHoverOctSpring, BtnHoverOctApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnOctSpring, BtnOctApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 2000,
    from: { opacity: 0 },
  }));

  const [BtnHoverNovSpring, BtnHoverNovApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnNovSpring, BtnNovApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 2100,
    from: { opacity: 0 },
  }));

  const [BtnHoverDecSpring, BtnHoverDecApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));

  const [BtnDecSpring, BtnDecApi] = useSpring(() => ({
    config: {
      duration: 250,
      easing: easings["easeOutExpo"],
    },
    delay: 2200,
    from: { opacity: 0 },
  }));

  React.useEffect(() => {
    StartPopOverApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 2000,
      config: { duration: 750 },
    });
    FindYourApi.start({
      ...{ opacity: 1 },
      delay: 2250,
      config: { duration: 250 },
    });
    BirthdayCocktailApi.start({
      ...{ opacity: 1 },
      delay: 2500,
      config: { duration: 250 },
    });
    StartHolderParentApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 1250,
      config: { duration: 750 },
    });
    SImg1HApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 2000,
      config: { duration: 750 },
    });
    SImg2HApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 2200,
      config: { duration: 750 },
    });
    SImg3HApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 2600,
      config: { duration: 750 },
    });
    SImg4HApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 2400,
      config: { duration: 750 },
    });
    SImg5HApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 2800,
      config: { duration: 750 },
    });
    SImg6HApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 3200,
      config: { duration: 750 },
    });
    SImg7HApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 3000,
      config: { duration: 750 },
    });
    SImg8HApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 3400,
      config: { duration: 750 },
    });
    BottlesDHolderApi.start({
      ...{ transform: "scale(1)" },
      delay: 0,
      config: { duration: 15000 },
    });
    BottlesMHolderApi.start({
      ...{ transform: "scale(1)" },
      delay: 0,
      config: { duration: 10000 },
    });
    SmImg1Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 2000,
      config: { duration: 750 },
    });
    SmImg2Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 2200,
      config: { duration: 750 },
    });
    SmImg3Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 2400,
      config: { duration: 750 },
    });
    SmImg4Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 2600,
      config: { duration: 750 },
    });
    SmImg5Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 2800,
      config: { duration: 750 },
    });
    SmImg6Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 3000,
      config: { duration: 750 },
    });
    SImg6Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 3000,
      config: { duration: 750 },
    });
    SImg5Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 3200,
      config: { duration: 750 },
    });
    SImg7Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 3400,
      config: { duration: 750 },
    });
    SImg8Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 3600,
      config: { duration: 750 },
    });
    SImg4Api.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 3600,
      config: { duration: 750 },
    });
    SImg3Api.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 3800,
      config: { duration: 750 },
    });
  }, []);

  return (
    <Property1Desktop className={props.className} props={props}>
      {props.isMobile && (
        <BottlesMHolder props={props} style={{ ...BottlesMHolderSpring }}>
          <BottlesM>
            <StartBottles>
              <RightSide>
                <SImg8
                  style={{ ...SImg8Spring }}
                  src={SImg8Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
                <SImg7
                  style={{ ...SImg7Spring }}
                  src={SImg7Image}
                  alt={"Hennessy Bottle and Cocktail7"}
                />
                <SImg6
                  style={{ ...SImg6Spring }}
                  src={SImg6Image}
                  alt={"Hennessy Cocktail"}
                />
                <SImg5
                  style={{ ...SImg5Spring }}
                  src={SImg5Image}
                  alt={"hennessy Cocktail"}
                />
              </RightSide>
              <LeftSide>
                <SImg4
                  style={{ ...SImg4Spring }}
                  src={SImg4Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
                <SImg3
                  style={{ ...SImg3Spring }}
                  src={SImg3Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
                <SImg2 src={SImg2Image} alt={"Hennessy Bottle and Cocktail"} />
                <SImg1 src={SImg1Image} alt={"Hennessy Bottle and Cocktail"} />
              </LeftSide>
            </StartBottles>
            <StartBottles1>
              <RightSide1>
                <SmImg8H>
                  <SmImg8
                    src={SmImg8Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg8H>
                <SmImg7H style={{ ...SmImg7HSpring }}>
                  <SmImg7
                    src={SmImg7Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg7H>
                <SmImg6H style={{ ...SmImg6HSpring }}>
                  <SmImg6
                    style={{ ...SmImg6Spring }}
                    src={SmImg6Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg6H>
                <SmImg5H style={{ ...SmImg5HSpring }}>
                  <SmImg5
                    style={{ ...SmImg5Spring }}
                    src={SmImg5Image}
                    alt={"Hennessy Cocktail"}
                  />
                </SmImg5H>
              </RightSide1>
              <LeftSide1>
                <SmImg4H style={{ ...SmImg4HSpring }}>
                  <SmImg4
                    style={{ ...SmImg4Spring }}
                    src={SmImg4Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg4H>
                <SmImg3H style={{ ...SmImg3HSpring }}>
                  <SmImg3
                    style={{ ...SmImg3Spring }}
                    src={SmImg3Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg3H>
                <SmImg2H style={{ ...SmImg2HSpring }}>
                  <SmImg2
                    style={{ ...SmImg2Spring }}
                    src={SmImg2Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg2H>
                <SmImg1H style={{ ...SmImg1HSpring }}>
                  <SmImg1
                    style={{ ...SmImg1Spring }}
                    src={SmImg1Image}
                    alt={"Hennessy Bottle and Cocktail"}
                  />
                </SmImg1H>
              </LeftSide1>
            </StartBottles1>
          </BottlesM>
        </BottlesMHolder>
      )}
      {!props.isMobile && (
        <BottlesDHolder props={props} style={{ ...BottlesDHolderSpring }}>
          <BottlesD>
            <RightSide2>
              <SImg8H style={{ ...SImg8HSpring }}>
                <SImg81
                  src={SImg81Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
              </SImg8H>
              <SImg7H style={{ ...SImg7HSpring }}>
                <SImg71
                  src={SImg71Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
              </SImg7H>
              <SImg6H style={{ ...SImg6HSpring }}>
                <SImg61 src={SImg61Image} alt={"Hennessy Cocktail"} />
              </SImg6H>
              <SImg5H style={{ ...SImg5HSpring }}>
                <SImg51 src={SImg51Image} alt={"Hennessy Cocktail"} />
              </SImg5H>
            </RightSide2>
            <LeftSide2>
              <SImg4H style={{ ...SImg4HSpring }}>
                <SImg41
                  style={{ ...SImg41Spring }}
                  src={SImg41Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
              </SImg4H>
              <SImg3H style={{ ...SImg3HSpring }}>
                <SImg31
                  style={{ ...SImg31Spring }}
                  src={SImg31Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
              </SImg3H>
              <SImg2H style={{ ...SImg2HSpring }}>
                <SImg21
                  style={{ ...SImg21Spring }}
                  src={SImg21Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
              </SImg2H>
              <SImg1H style={{ ...SImg1HSpring }}>
                <SImg11
                  style={{ ...SImg11Spring }}
                  src={SImg11Image}
                  alt={"Hennessy Bottle and Cocktail"}
                />
              </SImg1H>
            </LeftSide2>
          </BottlesD>
        </BottlesDHolder>
      )}
      <HideBottles props={props}>
        <HideJan props={props} style={{ ...HideJanSpring }}></HideJan>
        <HideFeb props={props} style={{ ...HideFebSpring }}></HideFeb>
        <HideMar props={props} style={{ ...HideMarSpring }}></HideMar>
        <HideApr props={props} style={{ ...HideAprSpring }}></HideApr>
        <HideMay props={props} style={{ ...HideMaySpring }}></HideMay>
        <HideJun props={props} style={{ ...HideJunSpring }}></HideJun>
        <HideJul props={props} style={{ ...HideJulSpring }}></HideJul>
        <HideAug props={props} style={{ ...HideAugSpring }}></HideAug>
        <HideSep props={props} style={{ ...HideSepSpring }}></HideSep>
        <HideOct props={props} style={{ ...HideOctSpring }}></HideOct>
        <HideNov props={props} style={{ ...HideNovSpring }}></HideNov>
        <HideDec props={props} style={{ ...HideDecSpring }}></HideDec>
      </HideBottles>
      <StartHolderParent props={props} style={{ ...StartHolderParentSpring }}>
        <StartHolder props={props} style={{ ...StartHolderSpring }}>
          <StartPopOver
            props={props}
            style={{ ...StartPopOverSpring }}
            aria-label={"Find your birthday Cocktail"}
          >
            <Blur props={props}></Blur>
            <DropShadow props={props}></DropShadow>
            <Txt id="findyourcocktail" props={props}>
              <FindYour props={props} style={{ ...FindYourSpring }}>
                {`Find your `}
              </FindYour>
              <BirthdayCocktail
                props={props}
                style={{ ...BirthdayCocktailSpring }}
              >
                {`Birthday Cocktail`}
              </BirthdayCocktail>
            </Txt>
            <Btn
              onClick={() => {
                logEvent(analytics, EVENT_NAMES.START_BUTTON)
                setIsMonth(true);
                // BtnApi.start({
                //   ...{ transform: "scale(1.1)", opacity: 0 },
                //   delay: 0,
                // });
                StartHolderApi.start({
                  ...{ transform: "translateY(500px)", opacity: 0 },
                  delay: 0,
                });
                SImg11Api.start({ ...{ opacity: 0 }, delay: 0 });
                SImg21Api.start({ ...{ opacity: 0 }, delay: 250 });
                SImg31Api.start({ ...{ opacity: 0 }, delay: 500 });
                SImg41Api.start({ ...{ opacity: 0 }, delay: 750 });
                SelectTxtApi.start({
                  ...{ transform: "translateX(-90px)" },
                  delay: 1000,
                });
                BtnJanApi.start({
                  ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 },
                  delay: 1100,
                });
                BtnFebApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1200 });
                BtnMarApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1300 });
                BtnAprApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1400 });
                BtnMayApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1500 });
                BtnJunApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1600 });
                BtnJulApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1700 });
                BtnAugApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1800 });
                BtnSepApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 1900 });
                BtnOctApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 2000 });
                BtnNovApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 2100 });
                BtnDecApi.start({ ...{ backgroundColor: "rgba(277, 277, 277, 1", opacity: 1 }, delay: 2200 });
                SelectYourBirthMonthApi.start({
                  ...{ opacity: 1 },
                  delay: 1000,
                });
                SmImg1HApi.start({ ...{ opacity: 0 }, delay: 0 });
                SmImg2HApi.start({ ...{ opacity: 0 }, delay: 250 });
                SmImg3HApi.start({ ...{ opacity: 0 }, delay: 500 });
                SmImg4HApi.start({ ...{ opacity: 0 }, delay: 750 });
                SmImg5HApi.start({ ...{ opacity: 0 }, delay: 1000 });
                SmImg6HApi.start({ ...{ opacity: 0 }, delay: 1250 });
                SmImg7HApi.start({ ...{ opacity: 0 }, delay: 1500 });
              }}
              onMouseOut={() => {
                BeginApi.start({ color: "rgb(77,77,77)" });
                BtnApi.start({
                  ...{ backgroundColor: "rgb(255,255,255)" },
                  delay: 0,
                });
              }}
              onMouseOver={() => {
                BeginApi.start({ ...{ color: "rgb(255,255,255)" }, delay: 0 });
                BtnApi.start({
                  ...{ backgroundColor: "rgb(39,38,35)" },
                  delay: 0,
                });
              }}
              props={props}
              style={{ ...BtnSpring }}
              aria-labelledby={"begin"}
            >
              <Begin id="begin" props={props} style={{ ...BeginSpring }}>
                BEGIN
              </Begin>
            </Btn>
          </StartPopOver>
        </StartHolder>
      </StartHolderParent>
      {isMonth && (
        <Months props={props} aria-labelledby="selectmonth">
          <EndDec props={props} style={{ ...EndDecSpring }}>
            <EndNov props={props} style={{ ...EndNovSpring }}>
              <EndOct props={props} style={{ ...EndOctSpring }}>
                <EndSep props={props} style={{ ...EndSepSpring }}>
                  <EndAug props={props} style={{ ...EndAugSpring }}>
                    <EndJul props={props} style={{ ...EndJulSpring }}>
                      <EndJun props={props} style={{ ...EndJunSpring }}>
                        <EndMay props={props} style={{ ...EndMaySpring }}>
                          <EndApr props={props} style={{ ...EndAprSpring }}>
                            <EndMar props={props} style={{ ...EndMarSpring }}>
                              <EndFeb props={props} style={{ ...EndFebSpring }}>
                                <EndJan style={{ ...EndJanSpring }}>
                                  <PickMonth props={props}>
                                    <SelectTxt
                                      props={props}
                                      style={{ ...SelectTxtSpring }}
                                    >
                                      <SelectYourBirthMonth
                                        id="selectmonth"
                                        props={props}
                                        style={{
                                          ...SelectYourBirthMonthSpring,
                                        }}
                                      >
                                        {`Select your birth month`}
                                      </SelectYourBirthMonth>
                                    </SelectTxt>
                                    <Months1
                                      props={props}
                                      aria-label={"Months"}
                                    >
                                      <Row1 props={props}>
                                        <BtnHoverJan>
                                          <BtnJan
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Jan");
                                                props.setStep(1);
                                              }, 1100);
                                              EndJanApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideJanApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnJanApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              JanApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnJanApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              JanApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnJanSpring }}
                                          >
                                            <Jan
                                              props={props}
                                              style={{ ...JanSpring }}
                                            >{`JAN`}</Jan>
                                          </BtnJan>
                                        </BtnHoverJan>
                                        <BtnHoverFeb
                                          props={props}
                                          style={{ ...BtnHoverFebSpring }}
                                        >
                                          <BtnFeb
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Feb");
                                                props.setStep(1);
                                              }, 1100);
                                              EndFebApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideFebApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnFebApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              FebApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnFebApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              FebApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnFebSpring }}
                                          >
                                            <Feb props={props} style={{ ...FebSpring }}>{`FEB`}</Feb>
                                          </BtnFeb>
                                        </BtnHoverFeb>
                                        <BtnHoverMar
                                          props={props}
                                          style={{ ...BtnHoverMarSpring }}
                                        >
                                          <BtnMar
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Mar");
                                                props.setStep(1);
                                              }, 1100);
                                              EndMarApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideMarApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnMarApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              MarApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnMarApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              MarApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnMarSpring }}
                                          >
                                            <Mar props={props} style={{ ...MarSpring }}>{`MAR`}</Mar>
                                          </BtnMar>
                                        </BtnHoverMar>
                                      </Row1>
                                      <Row2 props={props}>
                                        <BtnHoverApr
                                          style={{ ...BtnHoverAprSpring }}
                                        >
                                          <BtnApr
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Apr");
                                                props.setStep(1);
                                              }, 1100);
                                              EndAprApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideAprApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnAprApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              AprApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnAprApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              AprApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnAprSpring }}
                                          >
                                            <Apr props={props} style={{ ...AprSpring }}>{`APR`}</Apr>
                                          </BtnApr>
                                        </BtnHoverApr>
                                        <BtnHoverMay
                                          props={props}
                                          style={{ ...BtnHoverMaySpring }}
                                        >
                                          <BtnMay
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("May");
                                                props.setStep(1);
                                              }, 1100);
                                              EndMayApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideMayApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnMayApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              MayApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnMayApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              MayApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnMaySpring }}
                                          >
                                            <May props={props} style={{ ...MaySpring }}>{`MAY`}</May>
                                          </BtnMay>
                                        </BtnHoverMay>
                                        <BtnHoverJun
                                          props={props}
                                          style={{ ...BtnHoverJunSpring }}
                                        >
                                          <BtnJun
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Jun");
                                                props.setStep(1);
                                              }, 1100);
                                              EndJunApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideJunApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnJunApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              JunApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnJunApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              JunApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnJunSpring }}
                                          >
                                            <Jun props={props} style={{ ...JunSpring }}>{`JUN`}</Jun>
                                          </BtnJun>
                                        </BtnHoverJun>
                                      </Row2>
                                      <Row3 props={props}>
                                        <BtnHoverJul
                                          style={{ ...BtnHoverJulSpring }}
                                        >
                                          <BtnJul
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Jul");
                                                props.setStep(1);
                                              }, 1100);
                                              EndJulApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideJulApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnJulApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              JulApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnJulApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              JulApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnJulSpring }}
                                          >
                                            <Jul props={props} style={{ ...JulSpring }}>{`JUL`}</Jul>
                                          </BtnJul>
                                        </BtnHoverJul>
                                        <BtnHoverAug
                                          props={props}
                                          style={{ ...BtnHoverAugSpring }}
                                        >
                                          <BtnAug
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Aug");
                                                props.setStep(1);
                                              }, 1100);
                                              EndAugApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideAugApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnAugApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              AugApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnAugApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              AugApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnAugSpring }}
                                          >
                                            <Aug props={props} style={{ ...AugSpring }}>{`AUG`}</Aug>
                                          </BtnAug>
                                        </BtnHoverAug>
                                        <BtnHoverSep
                                          props={props}
                                          style={{ ...BtnHoverSepSpring }}
                                        >
                                          <BtnSep
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Sep");
                                                props.setStep(1);
                                              }, 1100);
                                              EndSepApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideSepApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnSepApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              SepApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnSepApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              SepApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnSepSpring }}
                                          >
                                            <Sep props={props} style={{ ...SepSpring }}>{`SEP`}</Sep>
                                          </BtnSep>
                                        </BtnHoverSep>
                                      </Row3>
                                      <Row4 props={props}>
                                        <BtnHoverOct
                                          style={{ ...BtnHoverOctSpring }}
                                        >
                                          <BtnOct
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Oct");
                                                props.setStep(1);
                                              }, 1100);
                                              HideOctApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                              EndOctApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnOctApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              OctApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnOctApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              OctApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnOctSpring }}
                                          >
                                            <Oct props={props} style={{ ...OctSpring }}>{`OCT`}</Oct>
                                          </BtnOct>
                                        </BtnHoverOct>
                                        <BtnHoverNov
                                          props={props}
                                          style={{ ...BtnHoverNovSpring }}
                                        >
                                          <BtnNov
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Nov");
                                                props.setStep(1);
                                              }, 1100);
                                              HideNovApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                              EndNovApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnNovApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              NovApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnNovApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              NovApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnNovSpring }}
                                          >
                                            <Nov props={props} style={{ ...NovSpring }}>{`NOV`}</Nov>
                                          </BtnNov>
                                        </BtnHoverNov>
                                        <BtnHoverDec
                                          props={props}
                                          style={{ ...BtnHoverDecSpring }}
                                        >
                                          <BtnDec
                                            onClick={() => {
                                              setTimeout(() => {
                                                props.setMonth("Dec");
                                                props.setStep(1);
                                              }, 1100);
                                              EndDecApi.start({
                                                ...{
                                                  transform:
                                                    "translateX(100px)",
                                                  opacity: 0,
                                                },
                                                delay: 0,
                                              });
                                              HideDecApi.start({
                                                ...{ opacity: 1 },
                                                delay: 500,
                                              });
                                            }}
                                            onMouseOut={() => {
                                              BtnDecApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(277, 277, 277, 1",
                                                  opacity: 1,
                                                },
                                              });
                                              DecApi.start({
                                                ...{
                                                  color:
                                                    "rgba(77, 77, 77, 1",
                                                },
                                              })
                                            }}
                                            onMouseOver={() => {
                                              BtnDecApi.start({
                                                ...{
                                                  backgroundColor:
                                                    "rgba(39,38,35,1)",
                                                  opacity: 1,
                                                },
                                                delay: 0,
                                              });
                                              DecApi.start({
                                                ...{
                                                  color:
                                                  "rgba(255, 255, 255, 1",
                                                },
                                              })
                                            }}
                                            props={props}
                                            style={{ ...BtnDecSpring }}
                                          >
                                            <Dec props={props} style={{ ...DecSpring }}>{`DEC`}</Dec>
                                          </BtnDec>
                                        </BtnHoverDec>
                                      </Row4>
                                    </Months1>
                                  </PickMonth>
                                </EndJan>
                              </EndFeb>
                            </EndMar>
                          </EndApr>
                        </EndMay>
                      </EndJun>
                    </EndJul>
                  </EndAug>
                </EndSep>
              </EndOct>
            </EndNov>
          </EndDec>
        </Months>
      )}
    </Property1Desktop>
  );
}

export default BirthdayPt1;
