import { parse, format } from "date-fns";
import { NEWSLETTER_DATE_FORMAT } from "src/constants/firebase.constants";

export const parseUIObjectToFirestoreObject = ({
  firstName,
  lastName,
  birthdate,
  email,
  zipcode,
  country,
}) => {
  const todaysFormattedDate = format(new Date(), NEWSLETTER_DATE_FORMAT);
  return {
    first_name: firstName,
    last_name: lastName,
    birthdate: format(parse(birthdate, 'MM/dd/yyyy', new Date()), NEWSLETTER_DATE_FORMAT),
    email,
    country_of_residence: country,
    opt_in: true,
    opt_in_date: todaysFormattedDate,
    updated: todaysFormattedDate,
    ...(zipcode ? { zip_code: zipcode?.replace(/\D/g, "") } : {})
  };
};
