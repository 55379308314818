import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import questTheme from 'src/QuestDesignSystem5LightTheme';
import useApp from './useApp';
import BirthdayPt1 from 'src/components/BirthdayPt1/BirthdayPt1';
import BirthdayPt2 from 'src/components/BirthdayPt2/BirthdayPt2';
import BirthdayPt3 from 'src/components/BirthdayPt3/BirthdayPt3';



function App() {
  const {data, fns} = useApp();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={questTheme}>
       {data.step === 0 && <BirthdayPt1 isMobile={data.isMobile} step={data.step} setStep={fns.setStep} setMonth={fns.setMonth}/>} 
       {[1, 2, 3].includes(data.step) && <BirthdayPt2 isMobile={data.isMobile} step={data.step} setStep={fns.setStep} setResponse={fns.setR} textVsop={data.textVsop} textVs={data.textVs}/>} 
       {data.step === 4 && <BirthdayPt3 isMobile={data.isMobile} setStep={fns.setStep} setResponse={fns.setR} metaData={data.metaData}/>} 
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;