/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import { styled } from "@mui/material/styles";
import { animated, useSpring, easings } from "react-spring";

const QuizButtonsM1 = animated(
  styled("button")({
    border: `2px solid rgba(202, 202, 202, 1)`,
    boxSizing: `border-box`,
    border: `2px solid rgba(77,77,77,1)`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    height: `47.9px`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `5px 20px`,
    width: "100%",
    cursor: `pointer`,
  })
);

const RacyRomanticRiveting = animated(styled("div")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `13px`,
  letterSpacing: `1.3px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}));

function QuizButtonsM(props) {
  const [QuizButtonsM1Spring, QuizButtonsM1Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsM2Spring, QuizButtonsM2Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsM3Spring, QuizButtonsM3Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsM4Spring, QuizButtonsM4Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [RacyRomanticSpring, RacyRomanticApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [QuizButtonsM5Spring, QuizButtonsM5Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsM6Spring, QuizButtonsM6Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const spring = {
    QuizButtonsM1Spring,
    QuizButtonsM2Spring,
    QuizButtonsM3Spring,
    QuizButtonsM4Spring,
    QuizButtonsM5Spring,
    QuizButtonsM6Spring,
  };

  const api = {
    QuizButtonsM1Api,
    QuizButtonsM2Api,
    QuizButtonsM3Api,
    QuizButtonsM4Api,
    QuizButtonsM5Api,
    QuizButtonsM6Api,
  };

  return (
    <QuizButtonsM1
      onClick={props.onClick}
      onMouseOver={() => {
        api["QuizButtons" + props.variant + "Api"].start({
          ...{ backgroundColor: "rgba(39,38,35,1)" },
          delay: 0,
        });
        RacyRomanticApi.start({
          ...{ color: "rgba(255,255,255,1)" },
          delay: 0,
        });
      }}
      onMouseOut={() => {
        api["QuizButtons" + props.variant + "Api"].start({
          backgroundColor: "rgba(255,255,255,1)",
        });
        RacyRomanticApi.start({
          ...{ color: "rgba(77,77,77,1)"},
          delay: 0,
        });
      }}
      className={props.className}
      style={{ ...spring["QuizButtons" + props.variant + "Spring"] }}
    >
      <RacyRomanticRiveting style={{ ...RacyRomanticSpring }}>
        {props.text}
      </RacyRomanticRiveting>
    </QuizButtonsM1>
  );
}

export default QuizButtonsM;
