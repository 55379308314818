import React, { useState, useEffect, useRef } from "react";
import { countries, months, years } from "../../info";
import getDaysInMonth from "date-fns/getDaysInMonth";
import { set } from "date-fns";
import { signUpToNewsletter } from "src/services/newsletter.service";

const useBirthdayPt3 = () => {
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [formInfo, setFormInfo] = useState({
    country: {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
    },
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState({
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  });
  const [zipcodeError, setZipcodeError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [month, setMonth] = useState();
  const [day, setDay] = useState();
  const [year, setYear] = useState();
  const [complete, setComplete] = useState(false);

  const mountRef = useRef(false);

  useEffect(() => {
    if (
      (Object.keys(formInfo).length === 9) ||
      (Object.keys(formInfo).length === 8 &&
        country.label !== "United States" &&
        !Object.keys(formInfo).includes("zipcode"))
    ) {
      setIsActive(true);
    } else (setIsActive(false))
  }, [formInfo, country]);

  useEffect(() => {
    if (firstName.trim().length) {
      setFormInfo({ ...formInfo, firstName });
    } else if (!firstName.trim().length && formInfo.firstName) {
      const newFormInfo = { ...formInfo };
      delete newFormInfo.firstName;
      setFormInfo(newFormInfo);
    }
  }, [firstName]);

  useEffect(() => {
    if (lastName.trim().length) setFormInfo({ ...formInfo, lastName });
    else if (!lastName.trim().length && formInfo.lastName) {
      const newFormInfo = { ...formInfo };
      delete newFormInfo.lastName;
      setFormInfo(newFormInfo);
    }
  }, [lastName]);

  useEffect(() => {
    if (month) {
      setFormInfo({ ...formInfo, month: month.val });
    }
  }, [month]);

  useEffect(() => {
    if (day) {
      setFormInfo({ ...formInfo, day });
    }
  }, [day]);

  useEffect(() => {
    if (year) {
      setFormInfo({ ...formInfo, year });
    }
  }, [year]);

  useEffect(() => {
    if (accepted === true) {
      setFormInfo({ ...formInfo, accepted });
    } else if (accepted === false && formInfo.accepted) {
      const newFormInfo = { ...formInfo };
      delete newFormInfo.accepted;
      setFormInfo(newFormInfo);
    }
  }, [accepted]);

  const addFirstName = (e) => {
    if (e.target.value.trim().length === 1) {
      setFirstName(e.target.value.trim().toUpperCase());
    } else {
      setFirstName(e.target.value);
    }
  };

  useEffect(() => {
    if (mountRef.current === true) {
      validateZipcode();
      setFormInfo({ ...formInfo, country });
    }
    else (mountRef.current = true)
  }, [country]);

  const addLastName = (e) => {
    if (e.target.value.trim().length === 1) {
      setLastName(e.target.value.trim().toUpperCase());
    } else {
      setLastName(e.target.value);
    }
  };

  const addEmail = (e) => {
    if (emailError) {
      setEmailError(false);
    }
    setEmail(e.target.value);
  };

  const addZipcode = (e) => {
    if (zipcodeError) {
      setZipcodeError(false);
    }
    setZipcode(e.target.value);
  };

  const validateEmail = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setFormInfo({ ...formInfo, email });
    } else {
      if (formInfo.email) {
        const newFormInfo = { ...formInfo };
        delete newFormInfo.email;
        setFormInfo(newFormInfo);
      }
      setEmailError(true);
    }
  };

  const validateZipcode = () => {
    if (
      zipcode.length === 5 &&
      !isNaN(Number(zipcode)) &&
      country.label === "United States"
    ) {
      setFormInfo({ ...formInfo, zipcode });
    } else if (country.label !== "United States") {
      if (zipcodeError) {
        setZipcodeError(false);
      }
      setFormInfo({ ...formInfo, zipcode });
    } else if (country.label === "United States") {
      if (formInfo.zipcode) {
        const newFormInfo = { ...formInfo };
        delete newFormInfo.zipcode;
        setFormInfo(newFormInfo);
        setZipcodeError(true);
      }
      if (zipcode.length !== 5 || isNaN(Number(zipcode))) {
        setZipcodeError(true);
      }
    }
  };

  const changeYear = (val) => {
    setYear(val);
    if (formInfo.day && formInfo.month) {
      const numberOfDays = getDaysInMonth(new Date(+val, +formInfo.month - 1));
      if (+formInfo.day > numberOfDays) {
        const newFormInfo = { ...formInfo };
        delete newFormInfo.day;
        setFormInfo(newFormInfo);
        setDay(null);
      }
    }
  };

  const changeMonth = (val) => {
    setMonth(val);
    if (formInfo.day && !formInfo.year) {
      const currentDate = new Date();
      const defaultYear = currentDate.getFullYear() - 21;
      const numberOfDays = getDaysInMonth(new Date(defaultYear, +val.val - 1));
      if (+formInfo.day > numberOfDays) {
        const newFormInfo = { ...formInfo };
        delete newFormInfo.day;
        setFormInfo(newFormInfo);
        setDay(null);
      }
    } else if (formInfo.day && formInfo.year) {
      const numberOfDays = getDaysInMonth(new Date(+formInfo.year, +val.val - 1));
      if (+formInfo.day > numberOfDays) {
        const newFormInfo = { ...formInfo };
        delete newFormInfo.day;
        setFormInfo(newFormInfo);
        setDay(null);
      }
    }
  };

  let days = [];
  if (!formInfo.year && !formInfo.month) {
    days = new Array(31)
      .fill("days")
      .map((day, i) => (day = (i + 1).toString()));
  } else if (!formInfo.year && formInfo.month) {
    const currentDate = new Date();
    const defaultYear = currentDate.getFullYear() - 21;
    const monthFromForm = +formInfo.month - 1;
    const numberOfDays = getDaysInMonth(new Date(defaultYear, monthFromForm));
    days = new Array(numberOfDays)
      .fill("days")
      .map((day, i) => (day = (i + 1).toString()));
  } else if (formInfo.year && formInfo.month) {
    const yearFromForm = +formInfo.year;
    const monthFromForm = +formInfo.month - 1;
    const numberOfDays = getDaysInMonth(new Date(yearFromForm, monthFromForm));
    days = new Array(numberOfDays)
      .fill("days")
      .map((day, i) => (day = (i + 1).toString()));
  }

  const processFormInfo = () => {
    const formData = {
      firstName: formInfo.firstName,
      lastName: formInfo.lastName,
      email: formInfo.email,
      zipcode: formInfo.zipcode,
      country: formInfo.country.label,
      birthdate: formInfo.month + "/" + (formInfo.day.length  === 1 ?  ("0" + formInfo.day) :  formInfo.day) + "/" + formInfo.year
    }; 
    signUpToNewsletter(formData)
    setComplete(true)
    setFormInfo({
      country: {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
      },
    })
  }

  const data = {
    isActive,
    firstName,
    lastName,
    email,
    emailError,
    zipcode,
    zipcodeError,
    accepted,
    countries,
    country,
    month,
    day,
    year,
    months,
    years,
    days,
    complete,
    isVisible
  };

  const fns = {
    addFirstName,
    addLastName,
    addEmail,
    validateEmail,
    addZipcode,
    validateZipcode,
    setAccepted,
    setCountry,
    changeMonth,
    setDay,
    changeYear,
    processFormInfo,
    setIsVisible
  };
  return { data, fns };
};

export default useBirthdayPt3;
