/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import { styled } from "@mui/material/styles";
import { animated, useSpring, easings } from "react-spring";

const QuizButtonsD1 = animated(
  styled("button")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 2px 4px rgba(231, 225, 213, 0.6)`,
    border: `2px solid rgba(202, 202, 202, 1)`,
    boxSizing: `border-box`,
    border: `2px solid rgba(77,77,77,1)`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    height: `78.01px`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `8px 34px`,
    width: "100%",
    cursor: `pointer`,
  })
);

const RacyRomanticRiveting = animated(
  styled("div")({
    textAlign: `center`,
    whiteSpace: `pre-wrap`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: `20px`,
    letterSpacing: `2px`,
    textDecoration: `none`,
    textTransform: `none`,
    margin: `0px`,
  })
);

function QuizButtonsD(props) {
  const [QuizButtonsD1Spring, QuizButtonsD1Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsD2Spring, QuizButtonsD2Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [RacyRomanticSpring, RacyRomanticApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(77,77,77,1)" },
  }));

  const [QuizButtonsD3Spring, QuizButtonsD3Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsD4Spring, QuizButtonsD4Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsD5Spring, QuizButtonsD5Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const [QuizButtonsD6Spring, QuizButtonsD6Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255,255,255,1)" },
  }));

  const spring = {
    QuizButtonsD1Spring,
    QuizButtonsD2Spring,
    QuizButtonsD3Spring,
    QuizButtonsD4Spring,
    QuizButtonsD5Spring,
    QuizButtonsD6Spring,
  };

  const api = {
    QuizButtonsD1Api,
    QuizButtonsD2Api,
    QuizButtonsD3Api,
    QuizButtonsD4Api,
    QuizButtonsD5Api,
    QuizButtonsD6Api,
  };

  return (
    <QuizButtonsD1
      onClick={props.onClick}
      onMouseOver={() => {
        api["QuizButtons" + props.variant + "Api"].start({
          ...{ backgroundColor: "rgba(39,38,35,1)" },
          delay: 0,
        });
        RacyRomanticApi.start({
          ...{ color: "rgba(255,255,255,1)" },
          delay: 0,
        });
      }}
      onMouseOut={() => {
        api["QuizButtons" + props.variant + "Api"].start({
          backgroundColor: "rgba(255,255,255,1)",
        });
        RacyRomanticApi.start({
          ...{ color: "rgba(77,77,77,1)"},
          delay: 0,
        });
      }}
      className={props.className}
      style={{ ...spring["QuizButtons" + props.variant + "Spring"] }}
    >
      <RacyRomanticRiveting style={{ ...RacyRomanticSpring }}>
        {props.text}
      </RacyRomanticRiveting>
    </QuizButtonsD1>
  );
}

export default QuizButtonsD;
