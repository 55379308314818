export const metadata = {
  JanVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/warm-tea-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Jan_VS",
    title: "WARM TEA COCKTAIL",
    description: "This cocktail is designed to channel your birthday energy into vibrant spaces even as it invokes the cozy, comfortable feeling of being at home. The rejuvenating brightness of spices and citrus combined with the floral lightness of hibiscus tea highlights the refined notes of Hennessy V.S and complements your creative, charismatic January personality."
  },
  JanVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/privilege-palmer-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Jan_VSOP",
    title: "V.S.O.P PALMER",
    description: "Prepare for a birthday celebration that’s an ode to your personality. This traditional Palmer with a Hennessy twist keeps it fresh and real, but with a strong touch of exuberance. Hennessy V.S.O.P ushers in spicy roundness while orange replaces the citric notes of lemon to bring an element of surprise to the evening. This is January redefined."
  },
  FebVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/brandy-crusta-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Feb_VS",
    title: "BRANDY CRUSTA",
    description: "Celebrate your birthday with a Hennessy V.S cocktail that matches your romantic February personality and is a vibrant dance between sweetness and spice. Created in New Orleans in 1840 by Joseph Santana, it brings together the sweetness of the sugared rim with the subtle notes of citrus and spice. Raise your glass to another year of living and loving life in your unique groovy style."
  },
  FebVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/red-jubilee-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc:  "Birthday_Drink_Feb_VSOP",
    title: "RED JUBILEE",
    description: "What better way to celebrate your multi-faceted February personality than with a Hennessy V.S.O.P cocktail. It combines the zing of five Chinese spices with the refreshing note of citrus to create a drink as diverse and balanced as you are. Whatever your birthday vibe, you’ll find it here."
  },
  MarVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/zombie-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Mar_VS",
    title: "ZOMBIE",
    description: "Raise a glass to the eternal spring in your step with this classic, refreshing birthday cocktail. The cherry adds the right amount of fruity sweetness to this subtly spiced tropical drink while Hennessy V.S further elevates the flavor and style of a March birthday."
  },
  MarVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/hennessy-tonic-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Mar_VSOP",
    title: "HENNESSY TONIC",
    description: "Raise a glass to your birthday with this Hennessy V.S.O.P cocktail that combines the spice of tonic and the touch of lime citrus to highlight the sweet roundness of Hennessy cognac. It’s the perfect tonic for your adventurous soul and pairs perfectly with a March birthday."
  },
  AprVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/spring-fling-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Apr_VS",
    title: "SPRING FLING",
    description: "This chilled-out cocktail is an exuberant April birthday celebration tamed in a glass. With the mellow spice of Hennessy V.S dancing with the sparkling fruit and citrus notes in this drink, it’s time for a mellifluous birthday rhapsody that’s perfect for spring."
  },
  AprVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/jalapina-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Apr_VSOP",
    title: "JALAPINA",
    description: "Conquer the unexpected with this Hennessy V.S.O.P twist on the countrywide favorite. Blending smooth sweet pineapple with a sudden kick of jalapeño, this drink blends diverse flavors with refinement and confident poise. In other words, a cocktail fit for the captivating April personality."
  },
  MayVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/sweet-tea-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_May_VS",
    title: "HENNESSY SWEET TEA",
    description: "Celebrate your May birthday with a Hennessy V.S cocktail that is not only a true classic, but also embraces the grandeur of the South. In this exquisite recipe, we add Hennessy Very Special and Grand Marnier™ to ensure you chill to a vibrant beat on your birthday."
  },
  MayVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/vsop-privilege-caipirinha-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_May_VSOP",
    title: "V.S.O.P. CAIPIRINHA",
    description: "Those born in May never allow the word “maybe” into their universe.  Celebrate your strong, self-assured nature with this lively and robust Hennessy V.S.O.P cocktail. It’s an intriguing take on a national favorite that swaps out the cachaça to add balance and interest. The flavors of the cognac are further enhanced with a simple orange twist. Here’s to making your birthday sparkle."
  },
  JunVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/coconut-berry-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Jun_VS",
    title: "COCONUT BERRY COCKTAIL",
    description: "As a June-born, you are always in the summer of life. This birthday, gift yourself a vacation in a glass with this ultimate cocktail. Hennessy V.S enhances the delicate flavor of coconut water, which in turn blends seamlessly with berries and the richness of the cognac. Take a sip, embark on an adventure."
  },
  JunVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/vsop-privilege-sour-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Jun_VSOP",
    title: "V.S.O.P SOUR",
    description: "The harmonious balance of the sweet and sour flavors of Hennessy V.S.O.P is the perfect way to celebrate your multi-faceted, balanced June personality and ring in your birthday. This elegant cocktail brings together different flavors in a smooth blend that is pure indulgence and refined contentment."
  },
  JulVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/american-75-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Jul_VS",
    title: "AMERICAN 75",
    description: "Celebrate your July personality and global outlook with a truly global birthday drink. This Hennessy V.S cocktail is an American take on the all-time classic French 75 and brings the sweet notes of cola and bright citrus to the classic bubbly cocktail. Encompassing the charm of two countries, this is the perfect choice for a global citizen like you.  Enjoy, as the world raises a glass to you."
  },
  JulVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/blueberry-mojito-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Jul_VSOP",
    title: "BLUEBERRY MOJITO",
    description: "This Hennessy V.S.O.P cocktail is perfect for your energetic July personality: it’s a classic with a Hennessy twist. It turns the mojito into a dynamic yet balanced fizz. Go ahead, experiment with different flavors, keep your birthday celebration dynamic, surprise yourself and see where the evening leads."
  },
  AugVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/hennyginger-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Aug_VS",
    title: "HENNYGINGER COCKTAIL",
    description: "As someone who plays it spicy, you need a drink that celebrates your birthday vibe. The tangy bite of ginger and the freshness of mint create the perfect Hennessy V.S cocktail to embark on your next adventure. Every sip sets the tone for a fresh, bright and spicy year. Here’s to your passion, pizzazz and vibrant August personality."
  },
  AugVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/french-75-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Aug_VSOP",
    title: "FRENCH 75",
    description: "Salute your accomplishments and your unique August personality with the perfect birthday cocktail. The richness of Hennessy V.S.O.P combined with the refined notes of champagne is a fitting tribute to all that you’ve achieved in life and how you achieved it—with impeccable style."
  },
  SepVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/apple-shrub-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Sep_VS",
    title: "APPLE SHRUB",
    description: "Take a sip of history and culture—it’s a birthday celebration your September personality would enjoy. The Apple Shrub, that dates back hundreds of years, brings together apple and spice in an inventive, complex and bold expression. Create history on your birthday, just as you do every single day."
  },
  SepVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/cozy-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Sep_VSOP",
    title: "COZY UP COCKTAIL",
    description: "Celebrate your authentic September personality this birthday with a cocktail that embraces the natural elements. Taste a balanced combination of alluring floral notes, rich V.S.O.P and spicy ginger. Let’s just say it’s the natural choice for a spectacular yet cozy birthday celebration with your inner circle."
  },
  OctVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/spiced-orange-smash-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Oct_VS",
    title: "SPICED ORANGE SMASH COCKTAIL",
    description: "Here’s a drink as radiant, refreshing and delightful as your fiery October personality, with a seamless blend of unexpected flavors. This version of the Smash comes with a spicy twist of Hennessy V.S, orange marmalade and bitters. Take a sip and make your birthday celebration a smash hit."
  },
  OctVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/espresso-martini-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Oct_VSOP",
    title: "ESPRESSO MARTINI",
    description: "Here’s to a birthday cocktail that’s an epitome of style with substance—just like you. Caffeine lovers and martini fans unite for this unique drink. Whether you like a strong, bitter Italian aperitif or a grown-up martini, this sweet caffeine cocktail is just the exuberant, energetic birthday surprise your October personality will appreciate."
  },
  NovVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/hot-toddy-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Nov_VS",
    title: "HOT TODDY",
    description: "Here's a cocktail that turns your birthday into a festive celebration, filled with snug moments. Cold November gets a cozy twist with this traditional winter drink. Hennessy V.S plays perfectly with the spice of cinnamon, the tang of citrus, and the sweet touch of honey. After all, you deserve the warmest winter birthday you can get."
  },
  NovVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/hennessy-downtown-manhattan?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Nov_VSOP",
    title: "HENNESSY DOWNTOWN MANHATTAN",
    description: "This birthday, head downtown or even around the world with this classic cocktail. Perfect for the global November personality, it features Hennessy and Italian Amaro with rich, complex notes that strike the perfect balance. Watch your evening come alive with promise and adventure."
  },
  DecVs: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/grand-manhattan-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Dec_VS",
    title: "THE GRAND MANHATTAN",
    description: "Your true grandeur lies in your simplicity and authenticity. Now, here's a classic Hennessy V.S cocktail for a birthday celebration that echoes your graceful grandeur.  Deep notes of spice meet candied fruit and play a warming note across the palate. This exquisite union of flavors is the perfect nightcap for a dynamic celebration of your December personality."
  },
  DecVsop: {
    recipeSrc: "https://www.hennessy.com/en-us/cocktails/hennessy-champagne-cocktail?utm_source=hennessy_bcp_page&utm_medium=return&utm_campaign=HENNESSY_WS_USA_birthdaycocktailprogram_COG_CR_HENNESSY_VSOP_CS&8yp@554g3g@t3",
    imageSrc: "Birthday_Drink_Dec_VSOP",
    title: "HENNESSY & CHAMPAGNE COCKTAIL",
    description: "Born in December, you carry the holiday spirit within you every day of the year. Here's a classic birthday cocktail for an epitome of class and joy like you. The Hennessy & Champagne cocktail has been the ultimate celebration drink since 1862. Take a sip and experience the subtle roundness of the cognac completing the sweet effervescence of champagne. Here’s to sparkling good times."
  },
}