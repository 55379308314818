import { addDoc, collection } from "firebase/firestore";
import { NEWSLETTER_COLLECTION_NAME } from "src/constants/firebase.constants";
import { db } from "src/firebase.config";
import { parseUIObjectToFirestoreObject } from "src/utils/newsletter.utils";

export const signUpToNewsletter = async ({
  firstName,
  lastName,
  birthdate,
  email,
  zipcode,
  country,
}) => {
  const newsletter = parseUIObjectToFirestoreObject({
    firstName,
    lastName,
    birthdate,
    email,
    zipcode,
    country,
  });
  return addDoc(collection(db, NEWSLETTER_COLLECTION_NAME), newsletter);
};
