/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import RetryIconImage from "src/assets/images/BirthdayPt3_retry_icon.png";
import { styled } from "@mui/material/styles";
import { animated, useSpring, easings } from "react-spring";
import Birthday_Drink_Jan_VS from "src/assets/images/Birthday Drink_Jan_VS.png";
import RetryIcon1Image from "src/assets/images/BirthdayPt3_retry_icon.png";
import Birthday_Drink_Jan_VSOP from "src/assets/images/Birthday Drink_Jan_VSOP.png";
import Birthday_Drink_Feb_VS from "src/assets/images/Birthday Drink_Feb_VS.png";
import Birthday_Drink_Feb_VSOP from "src/assets/images/Birthday Drink_Feb_VSOP.png";
import Birthday_Drink_Mar_VS from "src/assets/images/Birthday Drink_Mar_VS.png";
import Birthday_Drink_Mar_VSOP from "src/assets/images/Birthday Drink_Mar_VSOP.png";
import Birthday_Drink_Apr_VS from "src/assets/images/Birthday Drink_Apr_VS.png";
import Birthday_Drink_Apr_VSOP from "src/assets/images/Birthday Drink_Apr_VSOP.png";
import Birthday_Drink_May_VS from "src/assets/images/Birthday Drink_May_VS.png";
import Birthday_Drink_May_VSOP from "src/assets/images/Birthday Drink_May_VSOP.png";
import Birthday_Drink_Jun_VS from "src/assets/images/Birthday Drink_Jun_VS.png";
import Birthday_Drink_Jun_VSOP from "src/assets/images/Birthday Drink_Jun_VSOP.png";
import Birthday_Drink_Jul_VS from "src/assets/images/Birthday Drink_Jul_VS.png";
import Birthday_Drink_Jul_VSOP from "src/assets/images/Birthday Drink_Jul_VSOP.png";
import Birthday_Drink_Aug_VS from "src/assets/images/Birthday Drink_Aug_VS.png";
import Birthday_Drink_Aug_VSOP from "src/assets/images/Birthday Drink_Aug_VSOP.png";
import Birthday_Drink_Sep_VS from "src/assets/images/Birthday Drink_Sep_VS.png";
import Birthday_Drink_Sep_VSOP from "src/assets/images/Birthday Drink_Sep_VSOP.png";
import Birthday_Drink_Oct_VS from "src/assets/images/Birthday Drink_Oct_VS.png";
import Birthday_Drink_Oct_VSOP from "src/assets/images/Birthday Drink_Oct_VSOP.png";
import Birthday_Drink_Nov_VS from "src/assets/images/Birthday Drink_Nov_VS.png";
import Birthday_Drink_Nov_VSOP from "src/assets/images/Birthday Drink_Nov_VSOP.png";
import Birthday_Drink_Dec_VS from "src/assets/images/Birthday Drink_Dec_VS.png";
import Birthday_Drink_Dec_VSOP from "src/assets/images/Birthday Drink_Dec_VSOP.png";
import useBirthdayPt3 from "./useBirthdayPt3";
import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/firebase.config';
import { EVENT_NAMES } from 'src/constants/analytics.constants';

const Property1DYourDrink = styled("div", {
  shouldForwardProp: (prop) => !["props", "data"].includes(prop.toString()),
})(({ props, data }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: "100%",
  height: `770px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: props.isMobile && data.isActive ? `scroll` : `hidden`,
}));

const Pic = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `626px` : `1440px`,
    height: props.isMobile ? `417.33px` : `770px`,
    left: props.isMobile ? `-25px` : `0px`,
    top: props.isMobile ? `351px` : `0px`,
  }))
);

const BirthdayDrink = animated(
  styled("img", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    height: props.isMobile ? `417.33px` : `851px`,
    width: props.isMobile ? `626px` : `1276.5px`,
    position: `absolute`,
    left: props.isMobile ? `0px` : `545px`,
    top: props.isMobile ? `0px` : `-40px`,
  }))
);

const Left = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: props.isMobile ? `353px` : `690px`,
    height: props.isMobile ? `434.28px` : `692.01px`,
    left: props.isMobile ? `11px` : `44px`,
    top: props.isMobile ? `20px` : `25px`,
  }))
);

const YourBirthdayCocktail = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    color: `rgba(96, 96, 96, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `18px` : `21px`,
    letterSpacing: props.isMobile ? `1.8px` : `8.4px`,
    textDecoration: `none`,
    textTransform: `none`,
    position: `absolute`,
    left: props.isMobile ? `20px` : `23px`,
    top: `0px`,
    lineHeight: props.isMobile ? `22px` : "unset",
    width: props.isMobile ? `274px` : "unset",
  }))
);

const DrinkCard = animated(
  styled("section", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    backgroundColor: `rgba(255, 255, 255, 0.75)`,
    backdropFilter: props.isMobile ? `blur(34.78840255737305px)` : `blur(68px)`,
    WebkitBackdropFilter: props.isMobile
      ? `blur(34.78840255737305px)`
      : `blur(68px)`,
    boxShadow: props.isMobile
      ? `0px 2.0463767051696777px 7.162318229675293px rgba(0, 0, 0, 0.15)`
      : `0px 4px 14px rgba(0, 0, 0, 0.15)`,
    border: props.isMobile
      ? `0.5115941762924194px solid rgba(218, 218, 218, 1)`
      : `1px solid rgba(218, 218, 218, 1)`,
    boxSizing: `border-box`,
    borderRadius: props.isMobile ? `20px` : `28px`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `flex-start`,
    padding: props.isMobile ? `24px` : `40px`,
    width: props.isMobile ? `353px` : `690px`,
    left: `0px`,
    top: props.isMobile ? `44px` : `47px`,
  }))
);

const Drink = animated(
  styled("div", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Hennessy Sans`,
    fontWeight: `400`,
    fontSize: props.isMobile ? `22px` : `42px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    alignSelf: `stretch`,
    margin: `0px`,
  }))
);

const AboutDrink = animated(
  styled("p", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Gothic A1`,
    fontWeight: `500`,
    fontSize: props.isMobile ? `12px` : `20px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: props.isMobile ? `20px` : `34px`,
    textTransform: `none`,
    alignSelf: `stretch`,
    margin: props.isMobile
      ? `14.324636459350586px 0px 0px 0px`
      : `24px 0px 0px 0px`,
  }))
);

const Links = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile
    ? `14.324636459350586px 0px 0px 0px`
    : `24px 0px 0px 0px`,
}));

const BtnRecipe = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `3.154676675796509px 0px`
      : `6.344329833984375px 0px`,
    width: props.isMobile ? `142.21px` : `286px`,
    height: props.isMobile ? `28.84px` : `58px`,
    margin: `0px`,
    cursor: `pointer`,
  }))
);

const GetRecipe = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `14px` : `24px`,
  letterSpacing: props.isMobile ? `1.4px` : `2.4px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
})));

const BtnTryAgain = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    border: `2px solid rgba(77,77,77,1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `3.154676675796509px 0px`
      : `6.344329833984375px 0px`,
    width: props.isMobile ? `142.21px` : `286px`,
    height: props.isMobile ? `28.84px` : `58px`,
    margin: props.isMobile ? `0px 0px 0px 20px` : `0px 0px 0px 36px`,
    cursor: `pointer`,
  }))
);

const StartOver = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `14px` : `24px`,
  letterSpacing: props.isMobile ? `1.4px` : `1.44px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: props.isMobile
    ? `0px 0px 0px 9.477460861206055px`
    : `0px 0px 0px 19.059999465942383px`,
}))
);

const Divider = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile
    ? `5.115942001342773px 0px 0px 0px`
    : `10px 0px 0px 0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile
    ? `14.324636459350586px 0px 0px 0px`
    : `24px 0px 0px 0px`,
}));

const Frame8 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  backgroundColor: `rgba(202, 202, 202, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  alignSelf: `stretch`,
  height: props.isMobile ? `1.02px` : `2px`,
  margin: `0px`,
}));

const SignUpTxt = styled("p", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Gothic A1`,
  fontWeight: `500`,
  fontSize: props.isMobile ? `12px` : `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: props.isMobile ? `20px` : `32px`,
  textTransform: `none`,
  width: props.isMobile ? "unset" : `564px`,
  margin: props.isMobile
    ? `14.324636459350586px 0px 0px 0px`
    : `24px 0px 0px 0px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const SignUp = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  cursor: `pointer`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile
    ? `14.324636459350586px 0px 0px 0px`
    : `24px 0px 0px 0px`,
}));

const Btn = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    border: `2px solid rgb(77 77 77)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile
      ? `3.2457222938537598px 61.20504379272461px`
      : `6.344329833984375px 119.63592529296875px`,
    flexGrow: `1`,
    height: props.isMobile ? `29.68px` : `58.01px`,
    margin: `0px`,
  }))
);

const SignUp1 = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `15px` : `24px`,
  letterSpacing: props.isMobile ? `1.5px` : `2.4px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

const NewsletterCard = animated(
  styled("section", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    backgroundColor: `rgba(255, 255, 255, 0.75)`,
    backdropFilter: `blur(68px)`,
    WebkitBackdropFilter: `blur(68px)`,
    boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.15)`,
    border: `1px solid rgba(218, 218, 218, 1)`,
    boxSizing: `border-box`,
    borderRadius: `28px`,
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `flex-start`,
    padding: props.isMobile ? `18px 20px` : `34px 40px`,
    width: props.isMobile ? `360px` : `560px`,
    left: props.isMobile ? `8px` : `44px`,
    top: props.isMobile ? `820px` : `884px`,
  }))
);

const Form = styled("form", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: props.isMobile ? "unset" : `429px`,
  margin: `0px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const Title = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `10px 0px` : `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
}));

const NewsletterSignUp = styled("h1")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
});

const Name = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: props.isMobile ? `column` : `row`,
  justifyContent: props.isMobile ? `flex-end` : `flex-start`,
  alignItems: props.isMobile ? `flex-start` : `flex-end`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile ? `11px 0px 0px 0px` : `20px 0px 0px 0px`,
}));

const First = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-end`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: props.isMobile ? "unset" : `1`,
  margin: `0px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const Txt = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `14px`,
  width: `68px`,
  margin: `0px`,
});

const YourName = styled("h4")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  margin: `0px`,
});

const Frame2 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `8px 0px 0px 0px`,
});

const TextFieldOutlined = styled(TextField)({
  flexGrow: `1`,
  margin: `0px`,
});

const Last = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-end`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: props.isMobile ? "unset" : `1`,
  margin: props.isMobile ? `16px 0px 0px 0px` : `0px 0px 0px 20px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const Frame21 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-end`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const TextFieldOutlined1 = styled(TextField)({
  flexGrow: `1`,
  margin: `0px`,
});

const DateOfBirth = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile ? `11px 0px 0px 0px` : `20px 0px 0px 0px`,
}));

const Txt1 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `14px`,
  width: `84px`,
  margin: `0px`,
});

const DateOfBirth1 = styled("h4")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  margin: `0px`,
});

const Frame3 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `8px 0px 0px 0px`,
});

const Autocomplete1 = styled(Autocomplete)({
  flexGrow: `1`,
  margin: `0px`,
});

const Autocomplete2 = styled(Autocomplete)({
  flexGrow: `1`,
  margin: `0px 0px 0px 20px`,
});

const Autocomplete3 = styled(Autocomplete)({
  flexGrow: `1`,
  margin: `0px 0px 0px 20px`,
});

const EmailZip = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: props.isMobile ? `column` : `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile ? `11px 0px 0px 0px` : `20px 0px 0px 0px`,
}));

const Email = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: props.isMobile ? "unset" : `1`,
  margin: `0px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const Txt2 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `14px`,
  width: `70px`,
  margin: `0px`,
});

const YourEmail = styled("div")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Frame22 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `8px 0px 0px 0px`,
});

const TextFieldOutlined2 = styled(TextField)({
  flexGrow: `1`,
  margin: `0px`,
});

const Zip = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  flexGrow: props.isMobile ? "unset" : `1`,
  margin: props.isMobile ? `11px 0px 0px 0px` : `0px 0px 0px 20px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const Txt3 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `14px`,
  width: `51px`,
  margin: `0px`,
});

const Zipcode = styled("div")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Frame23 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `8px 0px 0px 0px`,
});

const TextFieldOutlined3 = styled(TextField)({
  flexGrow: `1`,
  margin: `0px`,
});

const Country = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile ? `11px 0px 0px 0px` : `20px 0px 0px 0px`,
}));

const Txt4 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `14px`,
  width: `57px`,
  margin: `0px`,
});

const Country1 = styled("div")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const CountryDd = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `8px 0px 0px 0px`,
});

const Autocomplete4 = styled(Autocomplete)({
  flexGrow: `1`,
  margin: `0px`,
});

const PrivacyCheckbox = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: props.isMobile ? `11px 0px 0px 0px` : `20px 0px 0px 0px`,
}));

const Checkbox1 = styled(Checkbox)({
  margin: `0px`,
});

const PrivacyDetails = styled("article", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `12px 0px 8px 0px` : `12px 0px 4px 0px`,
  boxSizing: `border-box`,
  flexGrow: `1`,
  margin: `0px 0px 0px 10px`,
}));

const PrivacyTextSpan1 = styled("span", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(52, 52, 52, 1)`,
  fontStyle: `normal`,
  fontFamily: `Gothic A1`,
  fontWeight: `500`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  textAlign: "unset",
  width: "unset",
  margin: "unset",
}));

const PrivacyTextSpan2 = styled("span", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 73, 216, 1)`,
  fontStyle: `normal`,
  fontFamily: `Gothic A1`,
  fontWeight: `500`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `underline`,
  lineHeight: `14px`,
  textTransform: `none`,
  textAlign: "unset",
  width: "unset",
  margin: "unset",
}));

const PrivacyTextSpan3 = styled("span", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(52, 52, 52, 1)`,
  fontStyle: `normal`,
  fontFamily: `Gothic A1`,
  fontWeight: `500`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  textAlign: "unset",
  width: "unset",
  margin: "unset",
}));

const PrivacyText = styled("p", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Gothic A1`,
  fontWeight: `500`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  width: props.isMobile ? `255px` : `357px`,
  margin: `0px`,
}));
const ThankYou = styled("section", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: props.isMobile ? "unset" : `429px`,
  margin: props.isMobile ? `12px 0px 0px 0px` : `24px 0px 0px 0px`,
  alignSelf: props.isMobile ? `stretch` : "unset",
}));

const Title1 = styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: props.isMobile ? `2px 0px` : `10px 0px`,
  boxSizing: `border-box`,
  margin: `0px`,
}));

const ThankYou1 = styled("h1")({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
});

const AboutDrink1 = styled("p", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Gothic A1`,
  fontWeight: `500`,
  fontSize: props.isMobile ? `12px` : `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: props.isMobile ? `20px` : `26px`,
  textTransform: `none`,
  alignSelf: `stretch`,
  margin: props.isMobile ? `15px 0px 0px 0px` : `18px 0px 0px 0px`,
}));

const BtnTryAgain1 = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
  })(({ props }) => ({
    border: `2px solid rgba(77, 77, 77, 1)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: props.isMobile ? `3px 0px` : `6.344329833984375px 0px`,
    height: props.isMobile ? `38px` : `58px`,
    width: props.isMobile ? `172px` : `246px`,
    margin: props.isMobile ? `15px 0px 0px 0px` : `18px 0px 0px 0px`,
  }))
);

const StartOver1 = animated(styled("div", {
  shouldForwardProp: (prop) => !["props"].includes(prop.toString()),
})(({ props }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: props.isMobile ? `16px` : `20px`,
  letterSpacing: props.isMobile ? `0.96px` : `1.2px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: props.isMobile
    ? `0px 0px 0px 14px`
    : `0px 0px 0px 19.059999465942383px`,
})));

const Btn1 = animated(
  styled("button", {
    shouldForwardProp: (prop) => !["props", "data"].includes(prop.toString()),
  })(({ props, data }) => ({
    border: data.isActive
      ? `2px solid rgba(77, 77, 77, 1)`
      : `2px solid rgb(211, 211, 211)`,
    boxSizing: `border-box`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `8px 20px`,
    margin: props.isMobile ? `11px 0px 0px 0px` : `20px 0px 0px 0px`,
  }))
);

const SignUp2 = animated(styled("div", {
  shouldForwardProp: (prop) => !["data"].includes(prop.toString()),
})(({ data }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  opacity: data.isActive ? 1 : 0.4,
  fontStyle: `normal`,
  fontFamily: `Hennessy Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `2.56px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}))
);

function BirthdayPt3(props) {
  const [PicSpring, PicApi] = useSpring(() => ({
    config: {
      duration: 25000,
      easing: easings["easeOutCubic"],
    },
    delay: 100,
    from: { transform: "scale(1.25)" },
  }));

  const [BirthdayDrinkSpring, BirthdayDrinkApi] = useSpring(() => ({
    config: {
      duration: 1250,
      easing: easings["easeOutExpo"],
    },
    delay: 1250,
    from: { transform: "translateX(100px)", opacity: 0 },
  }));

  const [LeftSpring, LeftApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeOutExpo"],
    },
    delay: 0,
    from: { opacity: 1 },
  }));

  const [YourBirthdayCocktailSpring, YourBirthdayCocktailApi] = useSpring(
    () => ({
      config: {
        duration: 750,
        easing: easings["easeInOutExpo"],
      },
      delay: 750,
      from: { transform: "translateX(100px)", opacity: 0 },
    })
  );

  const [DrinkCardSpring, DrinkCardApi] = useSpring(() => ({
    config: {
      duration: 1200,
      easing: easings["easeInOutQuint"],
    },
    delay: 1100,
    from: { transform: "translateY(700px)", opacity: 0 },
  }));

  const [DrinkSpring, DrinkApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 1550,
    from: { transform: "translateX(75px)", opacity: 0 },
  }));

  const [AboutDrinkSpring, AboutDrinkApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 1650,
    from: { transform: "translateX(75px)", opacity: 0 },
  }));

  const [BtnRecipeSpring, BtnRecipeApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255, 255, 255, 1)" },
  }));

  const [BtnTryAgainSpring, BtnTryAgainApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255, 255, 255, 1)" },
  }));

  const [StartOverSpring, StartOverApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(77, 77, 77, 1)" },
  }));

  const [StartOver1Spring, StartOver1Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(77, 77, 77, 1)" },
  }));

  const [GetRecipeSpring, GetRecipeApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(77, 77, 77, 1)" },
  }));

  const [SignUp1Spring, SignUp1Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(255,255,255,1)" },
  }));

  const [SignUp2Spring, SignUp2Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { color: "rgba(77, 77, 77, 1)" },
  }));

  const [BtnTryAgainSpring1, BtnTryAgainApi1] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255, 255, 255, 1)" },
  }));

  const [BtnSpring, BtnApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(77, 77, 77, 1)" },
  }));

  const [NewsletterCardSpring, NewsletterCardApi] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutExpo"],
    },
    delay: 0,
    from: { transform: "translateY(0px)" },
  }));

  const [Btn1Spring, Btn1Api] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { backgroundColor: "rgba(255, 255, 255, 1)" },
  }));

  React.useEffect(() => {
    BirthdayDrinkApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 550,
    });
    DrinkCardApi.start({
      ...{ transform: "translateY(0px)", opacity: 1 },
      delay: 400,
    });
    YourBirthdayCocktailApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 50,
    });
    PicApi.start({ ...{ transform: "scale(1)" }, delay: 100 });
    DrinkApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 850,
    });
    AboutDrinkApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 950,
    });
  }, []);

  const imageSrcMap = {
    Birthday_Drink_Jan_VS,
    Birthday_Drink_Jan_VSOP,
    Birthday_Drink_Feb_VS,
    Birthday_Drink_Feb_VSOP,
    Birthday_Drink_Mar_VS,
    Birthday_Drink_Mar_VSOP,
    Birthday_Drink_Apr_VS,
    Birthday_Drink_Apr_VSOP,
    Birthday_Drink_May_VS,
    Birthday_Drink_May_VSOP,
    Birthday_Drink_Jun_VS,
    Birthday_Drink_Jun_VSOP,
    Birthday_Drink_Jul_VS,
    Birthday_Drink_Jul_VSOP,
    Birthday_Drink_Aug_VS,
    Birthday_Drink_Aug_VSOP,
    Birthday_Drink_Sep_VS,
    Birthday_Drink_Sep_VSOP,
    Birthday_Drink_Oct_VS,
    Birthday_Drink_Oct_VSOP,
    Birthday_Drink_Nov_VS,
    Birthday_Drink_Nov_VSOP,
    Birthday_Drink_Dec_VS,
    Birthday_Drink_Dec_VSOP,
  };
  const { data, fns } = useBirthdayPt3();
  return (
    <Property1DYourDrink className={props.className} props={props} data={data}>
      <Pic props={props} style={{ ...PicSpring }}>
        <BirthdayDrink
          props={props}
          style={{ ...BirthdayDrinkSpring }}
          src={imageSrcMap[props.metaData.imageSrc]}
          alt={props.metaData.title + " drink"}
        />
      </Pic>
      <Left props={props} style={{ ...LeftSpring }}>
        <YourBirthdayCocktail
          id="yourbirthdaycocktail"
          props={props}
          style={{ ...YourBirthdayCocktailSpring }}
        >
          {`YOUR BIRTHDAY COCKTAIL:`}
        </YourBirthdayCocktail>
        <DrinkCard
          props={props}
          style={{ ...DrinkCardSpring }}
          aria-labelledby={"yourbirthdaycocktail"}
        >
          <Drink props={props} style={{ ...DrinkSpring }}>
            {props.metaData.title}
          </Drink>
          <AboutDrink props={props} style={{ ...AboutDrinkSpring }}>
            {props.metaData.description}
          </AboutDrink>
          <Links props={props} aria-label="Links">
            <BtnRecipe
              onClick={() => {
                logEvent(analytics, EVENT_NAMES.END_DRINK_RECIPE_LINK);
                setTimeout(() => {
                  window.open(props.metaData.recipeSrc, "_blank");
                }, 100);
              }}
              onMouseOut={() => {
                BtnRecipeApi.start({ backgroundColor: "rgba(255, 255, 255, 1)" });
                GetRecipeApi.start({
                  ...{ color: "rgba(77, 77, 77, 1)" },
                  delay: 0,
                });
              }}
              onMouseOver={() => {
                BtnRecipeApi.start({
                  ...{ backgroundColor: "rgba(39, 38, 35, 1)" },
                  delay: 0,
                });
                GetRecipeApi.start({
                  ...{ color: "rgba(255, 255, 255, 1)" },
                  delay: 0,
                });
              }}
              props={props}
              style={{ ...BtnRecipeSpring }}
            >
              <GetRecipe props={props} style={{...GetRecipeSpring}}>{`GET RECIPE`}</GetRecipe>
            </BtnRecipe>
            <BtnTryAgain
              onClick={() => {
                logEvent(analytics, EVENT_NAMES.END_START_OVER);
                setTimeout(() => {
                  props.setStep(0);
                  props.setResponse("reload");
                }, 100);
              }}
              onMouseOut={() => {
                BtnTryAgainApi.start({
                  backgroundColor: "rgba(255, 255, 255, 1)",
                });
                StartOverApi.start({
                  ...{ color: "rgba(77, 77, 77, 1)" },
                  delay: 0,
                });
              }}
              onMouseOver={() => {
                BtnTryAgainApi.start({
                  backgroundColor: "rgba(39, 38, 35, 1)",
                });
                StartOverApi.start({
                  ...{ color: "rgba(255, 255, 255, 1)" },
                  delay: 0,
                });
              }}
              props={props}
              style={{ ...BtnTryAgainSpring }}
            >
              <StartOver
                props={props}
                style={{ ...StartOverSpring }}
              >{`Start Over`}</StartOver>
            </BtnTryAgain>
          </Links>
          <Divider props={props}>
            <Frame8 props={props}></Frame8>
          </Divider>
          <SignUpTxt props={props}>
            {`Sign up for our newsletter to receive other great cocktails and a special present on your birthday.`}
          </SignUpTxt>
          <SignUp
            onClick={() => {
              logEvent(analytics, EVENT_NAMES.END_NEWSLETTER_SIGNUP);
              fns.setIsVisible(true);
              LeftApi.start({ ...{ opacity: 0 }, delay: 0 });
              NewsletterCardApi.start({
                ...{ transform: "translateY(-815px)" },
                delay: 0,
              });
            }}
            props={props}
          >
            <Btn
              onMouseOut={() => {
                BtnApi.start({ backgroundColor: "rgba(77, 77, 77, 1)" });
                SignUp1Api.start({ ...{ color: "rgba(255, 255, 255, 1)" }, delay: 0 });

              }}
              onMouseOver={() => {
                BtnApi.start({ ...{ backgroundColor: "rgba(255, 255, 255, 1)" }, delay: 0 });
                SignUp1Api.start({ ...{ color: "rgba(77, 77, 77, 1)" }, delay: 0 });

              }}
              props={props}
              style={{ ...BtnSpring }}
            >
              <SignUp1 props={props} style={{... SignUp1Spring}}>{`Sign UP`}</SignUp1>
            </Btn>
          </SignUp>
        </DrinkCard>
      </Left>
      {data.isVisible && (
        <NewsletterCard
          props={props}
          style={{ ...NewsletterCardSpring }}
          aria-labelledby="title"
        >
          {!data.complete && (
            <Form props={props}>
              <Title props={props}>
                <NewsletterSignUp id="title">{`Newsletter Sign Up`}</NewsletterSignUp>
              </Title>
              <Name props={props} aria-labelledby={"yourname"}>
                <First props={props}>
                  <Txt>
                    <YourName id="yourname">{`YOUR NAME`}</YourName>
                  </Txt>
                  <Frame2>
                    <TextFieldOutlined
                      variant="outlined"
                      size="medium"
                      value={data.firstName}
                      onChange={fns.addFirstName}
                      placeholder={"First Name"}
                      inputProps={{
                        "aria-label": "first name",
                        style: { "fontFamily": "Gothic A1" },
                      }}
                    />
                  </Frame2>
                </First>
                <Last props={props}>
                  <Frame21>
                    <TextFieldOutlined1
                      variant="outlined"
                      size="medium"
                      value={data.lastName}
                      onChange={fns.addLastName}
                      placeholder={"Last Name"}
                      inputProps={{
                        "aria-label": "last name",
                        style: { "fontFamily": "Gothic A1" },
                      }}
                    />
                  </Frame21>
                </Last>
              </Name>
              <DateOfBirth props={props} aria-labelledby={"dob"}>
                <Txt1>
                  <DateOfBirth1 id="dob">{`Date of Birth`}</DateOfBirth1>
                </Txt1>
                <Frame3>
                  <Autocomplete1
                    autoHighlight
                    disableClearable
                    openOnFocus
                    onChange={(e, val) => fns.changeMonth(val)}
                    options={data.months}
                    blurOnSelect
                    value={data.month || null}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        fullWidth
                        placeholder={`Month`}
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "month of birth",
                          style: { "fontFamily": "Gothic A1" },
                        }}
                      />
                    )}
                  />
                  <Autocomplete2
                    autoHighlight
                    disableClearable
                    openOnFocus
                    onChange={(e, val) => fns.setDay(val)}
                    options={data.days}
                    blurOnSelect
                    value={data.day || null}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        id="my-input"
                        variant="outlined"
                        {...params}
                        fullWidth
                        placeholder={`Day`}
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "date of birth",
                          style: { "fontFamily": "Gothic A1" },
                        }}
                      />
                    )}
                  />
                  <Autocomplete3
                    autoHighlight
                    disableClearable
                    openOnFocus
                    onChange={(e, val) => fns.changeYear(val)}
                    options={data.years}
                    blurOnSelect
                    value={data.year || null}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        fullWidth
                        placeholder={`Year`}
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "year of birth",
                          style: { "fontFamily": "Gothic A1" },
                        }}
                      />
                    )}
                  />
                </Frame3>
              </DateOfBirth>
              <EmailZip props={props}>
                <Email props={props}>
                  <Txt2>
                    <YourEmail>{`YOUR EMAIL`}</YourEmail>
                  </Txt2>
                  <Frame22>
                    <TextFieldOutlined2
                      variant="outlined"
                      size="medium"
                      value={data.email}
                      onChange={fns.addEmail}
                      onBlur={fns.validateEmail}
                      placeholder={"Email Address"}
                      helperText={
                        data.emailError
                          ? "Please enter a valid email address"
                          : ""
                      }
                      inputProps={{
                        "aria-label": "email address",
                        style: { "fontFamily": "Gothic A1" },
                      }}
                    />
                  </Frame22>
                </Email>
                <Zip props={props}>
                  <Txt3>
                    <Zipcode>{`Zipcode`}</Zipcode>
                  </Txt3>
                  <Frame23>
                    <TextFieldOutlined3
                      variant="outlined"
                      size="medium"
                      value={data.zipcode}
                      onChange={fns.addZipcode}
                      onBlur={fns.validateZipcode}
                      placeholder={"Your Zipcode"}
                      helperText={
                        data.zipcodeError
                          ? "Please enter your 5-digit zipcode"
                          : ""
                      }
                      inputProps={{
                        "aria-label": "zipcode",
                        style: { "fontFamily": "Gothic A1" },
                      }}
                    />
                  </Frame23>
                </Zip>
              </EmailZip>
              <Country props={props}>
                <Txt4>
                  <Country1>{`Country`}</Country1>
                </Txt4>
                <CountryDd>
                  <Autocomplete4
                    autoHighlight
                    disableClearable
                    openOnFocus
                    blurOnSelect
                    onChange={(e, val) => {
                      fns.setCountry(val);
                    }}
                    options={data.countries}
                    getOptionLabel={(option) => option.label}
                    value={data.country}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        fullWidth
                        placeholder={`Country`}
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "your country",
                          style: { "fontFamily": "Gothic A1" },
                        }}
                      />
                    )}
                  />
                </CountryDd>
              </Country>
              <PrivacyCheckbox props={props}>
                <Checkbox1
                  checked={data.accepted}
                  onChange={() => fns.setAccepted(!data.accepted)}
                  size="small"
                  color="primary"
                />
                <PrivacyDetails props={props} aria-labelledby={"privacy"}>
                  <PrivacyText props={props}>
                    <PrivacyTextSpan1
                      props={props}
                    >{`I would like to get information about Hennessy, its products, offers, and other related information. I understand that Hennessy may share this information with other Moët-Hennessy USA brands for their own use. I understand that my information will be shared with Hennessy and used as described in the `}</PrivacyTextSpan1>
                    <a href="https://www.hennessy.com/en-us/privacy-cookies-notice" target="_blank" rel="noopener noreferrer">
                    <PrivacyTextSpan2
                      id="privacy"
                      props={props}
                    >{`Privacy Policy`}</PrivacyTextSpan2>
                    </a>
                    <PrivacyTextSpan3 props={props}>{`.`}</PrivacyTextSpan3>
                  </PrivacyText>
                </PrivacyDetails>
              </PrivacyCheckbox>
              <Btn1
                onClick={(e) => {
                  logEvent(analytics, EVENT_NAMES.END_NEWSLETTER_SIGNUP);
                  e.preventDefault();
                  if (data.isActive) fns.processFormInfo();
                }}
                onMouseOut={() => {
                  if (data.isActive) {
                    Btn1Api.start({ backgroundColor: "rgba(255, 255, 255, 1)" });
                    SignUp2Api.start({ ...{ color: "rgba(77, 77, 77, 1)" }, delay: 0 });
                  }
                }}
                onMouseOver={() => {
                  if (data.isActive) {
                    Btn1Api.start({ ...{ backgroundColor: "rgba(39, 38, 35, 1)" }, delay: 0 });
                    SignUp2Api.start({ ...{ color: "rgba(255, 255, 255, 1)" }, delay: 0 });

                  }
                }}
                props={props}
                data={data}
                style={{ ...Btn1Spring }}
              >
                <SignUp2 data={data} style={{... SignUp2Spring}}>{`SIGN UP`}</SignUp2>
              </Btn1>
            </Form>
          )}
          {data.complete && (
            <ThankYou props={props} aria-labelledby="thankyou">
              <Title1 props={props}>
                <ThankYou1 id="thankyou">{`Thank You`}</ThankYou1>
              </Title1>
              <AboutDrink1 props={props}>
                {`You’ll now be included in future Hennessy birthday cocktail recipes and promotions.`}
              </AboutDrink1>
              <BtnTryAgain1
                onClick={() => {
                  logEvent(analytics, EVENT_NAMES.END_NEWSLETTER_SUBMITTED_START_OVER)
                  setTimeout(() => {
                    props.setStep(0);
                    props.setResponse("reload");
                  }, 100);
                }}
                onMouseOut={() => {
                  BtnTryAgainApi1.start({ backgroundColor: "rgba(255, 255, 255, 1)" });
                  StartOver1Api.start({
                    ...{ color: "rgba(77, 77, 77, 1)" },
                    delay: 0,
                  })
                }}
                onMouseOver={() => {
                  BtnTryAgainApi1.start({
                    ...{ backgroundColor: "rgba(39, 38, 35, 1)" },
                    delay: 0,
                  });
                  StartOver1Api.start({
                    ...{ color: "rgba(255, 255, 255, 1)" },
                    delay: 0,
                  })
                }}
                props={props}
                style={{ ...BtnTryAgainSpring1 }}
              >
                <StartOver1 props={props} style={{...StartOver1Spring}}>{`Start Over`}</StartOver1>
              </BtnTryAgain1>
            </ThankYou>
          )}
        </NewsletterCard>
      )}
    </Property1DYourDrink>
  );
}

export default BirthdayPt3;
