import React, { useState, useEffect } from 'react';
import useResponsiveSize from './useResponsive';
import { metadata } from './metadata';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase.config';
import { EVENT_NAMES } from './constants/analytics.constants';

const useApp = () => {
  const [step, setStep] = useState(0);
  const [month, setMonth] = useState(null);
  const [response, setResponse] = useState({Vs: 0, Vsop: 0})
  const [textVsop, setTextVsop] = useState("");
  const [textVs, setTextVs] = useState("");

  useEffect(() => {
    let newTextVs = '';
    let newTextVsop = '';
    switch(true) {
      case(month === "Jan" && step === 1):
      newTextVs = "COZY, CREATIVE, CHARISMATIC";
      newTextVsop = "FRESH, FUNKY, FEISTY";
      break;      
      case(month === "Feb" && step === 1):
      newTextVs = "RACY, ROMANTIC, RIVETING";
      newTextVsop = "BOLD, BRIGHT, BALANCED";
      break;      
      case(month === "Mar" && step === 1):
      newTextVs = "FRESH, FUN, FLAVORFUL";
      newTextVsop = "SCINTILLATING, SPIRITED, SOULFUL";
      break;      
      case(month === "Apr" && step === 1):
      newTextVs = "ECLECTIC, EXCITING, ENERGETIC";
      newTextVsop = "CONFIDENT, CAPTIVATING, CHARMING";
      break;      
      case(month === "May" && step === 1):
      newTextVs = "GRAND, GROUNDED, GLOWING";
      newTextVsop = "FEARLESS, FREE-FLOWING, FIERY";
      break;      
      case(month === "Jun" && step === 1):
      newTextVs = "BREEZY, BRIGHT, BUOYANT";
      newTextVsop = "HAPPY, HARMONIOUS, HEROIC";
      break;      case(month === "Jul" && step === 1):
      newTextVs = "GLOBAL, GRACEFUL, GRITTY";
      newTextVsop = "DYNAMIC, DARING, DISTINCT";
      break;      
      case(month === "Aug" && step === 1):
      newTextVs = "ADVENTUROUS, ALLURING, ASCENDING";
      newTextVsop = "SMOOTH, STYLISH, SUCCESSFUL";
      break;     
      case(month === "Sep" && step === 1):
      newTextVs = "HISTORIC, HARMONIOUS, HEARTY";
      newTextVsop = "COZY, CAPTIVATING, CATHARTIC";
      break;      
      case(month === "Oct" && step === 1):
      newTextVs = "FEISTY, FRUITFUL, FABULOUS";
      newTextVsop = "EXUBERANT, EXCEPTIONAL, EFFERVESCENT";
      break;      
      case(month === "Nov" && step === 1):
      newTextVs = "WARM, WINSOME, WONDROUS";
      newTextVsop = "MAJESTIC, MAGNETIC, METEORIC";
      break;      
      case(month === "Dec" && step === 1):
      newTextVs = "GLORIOUS, GENUINE, GRACIOUS";
      newTextVsop = "CHEERFUL, COMPELLING, CELEBRATORY";
      break;      
      case(month === "Jan" && step === 2):
      newTextVs = "SPICED, CITRUSY, FLORAL";
      newTextVsop = "SPICY, ORANGE, SOUR";
      break;      
      case(month === "Feb" && step === 2):
      newTextVs = "SPICED, SWEET, COMPLEX";
      newTextVsop = "CITRUSY, TANGY, SMOOTH";
      break;      
      case(month === "Mar" && step === 2):
      newTextVs = "SWEET, SPICED, TROPICAL";
      newTextVsop = "CITRUSY, SWEET, TART";
      break;      
      case(month === "Apr" && step === 2):
      newTextVs = "SPICED, FRUITY, EFFERVESCENT";
      newTextVsop = "SPICY, SWEET, SMOOTH";
      break;      
      case(month === "May" && step === 2):
      newTextVs = "SOUR, CITRUS, ROBUST";
      newTextVsop = "SWEET, SOUR, RICH";
      break;      
      case(month === "Jun" && step === 2):
      newTextVs = "FRUITY, DELICATE, RICH";
      newTextVsop = "SWEET, SOUR, BALANCED";
      break;      
      case(month === "Jul" && step === 2):
      newTextVs = "FIZZY, SWEET, FRUITY";
      newTextVsop = "SWEET, BRIGHT, CITRUSY";
      break;      
      case(month === "Aug" && step === 2):
      newTextVs = "TANGY, FRESH, SPICY";
      newTextVsop = "SWEET, FRUITY, SOUR";
      break;      
      case(month === "Sep" && step === 2):
      newTextVs = "BOLD, SPICED, SOUR";
      newTextVsop = "FLORAL, SPICY, COMPLEX";
      break;      
      case(month === "Oct" && step === 2):
      newTextVs = "FRUITY, TART, SOUR";
      newTextVsop = "SWEET, TART, SMOOTH";
      break;      
      case(month === "Nov" && step === 2):
      newTextVs = "SPICED, CITRUSY, SWEET";
      newTextVsop = "RICH, FRUITY, COMPLEX";
      break;      
      case(month === "Dec" && step === 2):
      newTextVs = "FRUITY, SWEET, TART";
      newTextVsop = "SUBTLE, SWEET, EFFERVESCENT";
      break;      
      case(step === 3):
      newTextVs = "BOLD, ROBUST, FRAGRANT";
      newTextVsop = "HARMONIOUS, BALANCED, VITAL";
    }
    setTextVsop(newTextVsop);
    setTextVs(newTextVs);

  }, [month, step])
  const size = useResponsiveSize();
  const VsOrVsop = response['Vs'] > response['Vsop'] ? 'Vs' :'Vsop';
  const metaData = month &&  response['Vs'] + response['Vsop']  === 3  ? metadata[(month + VsOrVsop)] : {}
  const data = {step, isMobile: size === "mobile", textVsop, textVs, metaData};

  const setR = (key) => {
    if (key === 'reload') {
      setResponse({Vs: 0, Vsop: 0})
    } else {
      const newState = {...response, [key]: response[key]+1}
      setResponse(newState)
    }
  }

  useEffect(() => {
    if(step === 4) {
      logEvent(analytics, EVENT_NAMES.END_DRINK_RESULT);
    }
  }, [step])

  const fns = {setStep, setMonth, setR};

  return {data, fns};
}
export default useApp;