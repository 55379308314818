export const EVENT_NAMES = {
  START_BUTTON: "q1_answer_1",
  Q1_ANSWER_1: "q1_answer_1",
  Q1_ANSWER_2: "q1_answer_2",
  Q2_ANSWER_1: "q2_answer_1",
  Q2_ANSWER_2: "q2_answer_2",
  Q3_ANSWER_1: "q3_answer_1",
  Q3_ANSWER_2: "q3_answer_2",
  END_START_OVER: "end_start_over",
  END_DRINK_RESULT: "end_drink_result",
  END_DRINK_RECIPE_LINK: "end_drink_recipe_link",
  END_NEWSLETTER_SIGNUP: "end_newsletter_signup",
  END_NEWSLETTER_SUBMIT: "end_newsletter_submit",
  END_NEWSLETTER_SUBMITTED_START_OVER: "end_newsletter_submitted_start_over",
};
