// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyChhHVqEzJeDdp91HPAhE4rCpdSjT4aTko",
  authDomain: "hennessy-eb6b1.firebaseapp.com",
  projectId: "hennessy-eb6b1",
  storageBucket: "hennessy-eb6b1.appspot.com",
  messagingSenderId: "564145080969",
  appId: "1:564145080969:web:d2c31d8c27d157a0821458",
  measurementId: "G-VVLY8Y0S10"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeVMpEjAAAAADyvU6Ldi10JEnGKWrF3PXaYWYHs'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

